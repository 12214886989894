import React from 'react'

const SessionExpired = (props) => {
  return (
    <div>
        <p>Session expired!</p>
        <p>Please login again.</p>
        <button style={{padding:'12px 30px', background:'#000bff', borderRadius:'10px', border:'3px solid #dfae1a', color:'#fff', fontSize:'1rem'}} onClick={props.onClick}>Login</button>
    </div>
  )
}

export default SessionExpired;