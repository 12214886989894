import React, { useEffect, useState } from 'react'
import Slider from "./Slider";
import Calavera from '../../assets/Slots/Slot_Sprite/Calavera.png'
import SunFire from '../../assets/Slots/Slot_Sprite/SunFire.png'
import LinkHit from '../../assets/Link/Link-Hit-Logo.png'
import Piramide from '../../assets/Slots/Slot_Sprite/Piramide.png'
import ReturnButton from '../../assets/Tutorials/Return-Button.png'
import FoF from '../../assets/Link/FoF-Logo.png'
import btnCircle from '../../assets/Backgrounds/Hud_tutoriales.png'
import btnInfo from '../../assets/Backgrounds/Hud_Game1of10.png'
import btnBet from '../../assets/Backgrounds/Hud_Bet.png'
import axios from 'axios';

const SliderItems = (props) => {
  const playerId = localStorage.getItem("playerId");
  const token = localStorage.getItem("token");
  const [payoutApiData, setPayoutApiData] = useState([])
  const [payLineApiData, setPayLineApiData] = useState([])
  const [payoutApiDataSecond, setPayoutApiDataSecond] = useState([])
  function imgHandle(img) {
    if (img === "HH") {
      return "/assets/Slots/Slot_Sprite/A_royal_dorado.png";
    }
    if (img === "KK") {
      return "/assets/Slots/Slot_Sprite/J_royal_dorado.png";
    }
    if (img === "II") {
      return "/assets/Slots/Slot_Sprite/K_royal_dorado.png";
    }
    if (img === "FF") {
      return "/assets/Slots/Slot_Sprite/AntorchaA.png";
    }
    if (img === "WW") {
      return "/assets/Slots/Slot_Sprite/Calavera.png";
    }
    if (img === "CC") {
      return "/assets/Slots/Slot_Sprite/Exploradora_Simbolo_2.png";
    }
    if (img === "DD") {
      return "/assets/Slots/Slot_Sprite/gema.png";
    }
    if (img === "GG") {
      return "/assets/Slots/Slot_Sprite/Moneda.png";
    }
    if (img === "SS") {
      return "/assets/Slots/Slot_Sprite/Piramide.png";
    }
    if (img === "JJ") {
      return "/assets/Slots/Slot_Sprite/Q_royal_dorado.png";
    }
    if (img === "EE") {
      return "/assets/Slots/Slot_Sprite/quetzal.png";
    }
    if (img === "TT") {
      return "/assets/Slots/Slot_Sprite/SunFire.png";
    }
  }

  useEffect(()=>{

    (async () => {
     await PayOutDataApi()
     await PayLineDataApi()
    })();
  
    return () => {
      // this now gets called when the component unmounts
    };
  },[])
  
  const PayOutDataApi = async () => {
    try {
      let apiData = await axios.post(`${process.env.REACT_APP_API_URL}/GamePlay/GamePayout?gameid=1`, false, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPayoutApiData(apiData?.data?.resultData?.splice(5));
      setPayoutApiDataSecond(apiData?.data?.resultData);
    } catch (error) {
      console.log(error)
    }

  }



  const PayLineDataApi = async () => {
    try {
      let apiData = await axios.post(`${process.env.REACT_APP_API_URL}/GamePlay/GamePaylineDetails?gameid=1`, false, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPayLineApiData(apiData?.data?.resultData);
    } catch (error) {
      console.log(error)
    }

  }

  console.log(payLineApiData,'payLineApiData')

  payoutApiData?.splice(5)
  // payoutApiDataSecond.splice(0, 5);
  console.log(payoutApiData, 'api data payout data', payoutApiDataSecond)
 

  return (
    <>
      <Slider height="100vh" speed={3000}>

        {/* payout Table page code starts here */}
        <div style={{ width: "100%", height: "100vh" }}>
          <div className='payoutFistPage'>
            <div className='cardHead'>
              <div className='card'>
                <img src={Calavera} alt='' />
                <div className='cardTextt bebas-neue-regular'>
                <p>Substitutes for all symbols except scatter symbols </p> <img src={Piramide} alt='' /> <p>& symbols </p><img src={SunFire} alt='' />
                </div>
              </div>
              <div className='cardCenter'>
                <img src={FoF} alt='' />
                <div className='cardTextt bebas-neue-regular'>
                <p>6 or more symbols </p> <img src={SunFire} alt='' /> <p>on any spot trigger </p><img src={LinkHit} alt='' />
              </div>
              </div>
              <div className='card'>
                <div className='imgContainer'>
                  <img src={Piramide} alt='' />
                  <div style={{width:'100%'}} className='cardTextt bebas-neue-regular'>
                  <p>3, 4 & 5 scatter symbols </p> <img src={Piramide} alt='' /> <p>award 5 free games.</p>
                  </div>
                  <div style={{width:'70%', margin:"0 auto"}} className='cardText'>
                  <div className='cardTextt bebas-neue-regular'>
                            <p><span className='symOccurence'>5</span></p><img src={Piramide} alt='' /> <p>BET X100</p>
                  </div>
                  <div className='cardTextt bebas-neue-regular'>
                            <p><span className='symOccurence'>4</span></p><img src={Piramide} alt='' /> <p>BET X10</p>
                  </div>
                  <div className='cardTextt bebas-neue-regular'>
                            <p><span className='symOccurence'>3</span></p><img src={Piramide} alt='' /> <p>BET X2</p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='payOutBottom'>
              <div className='bottomCards'>
                {
                  payoutApiDataSecond?.map((el) => {
                    return (
                      <div className='card' key={el.symbolGroupId}>
                        <div className='imgContainer'>
                          <img src={imgHandle(el.symbol)} alt='' />
                          <p className='bebas-neue-regular'><span className='symOccurence' style={{marginRight:'10px'}}>{el.occurance[2]} </span>  {el.winAmount[2]}</p>
                          <p className='bebas-neue-regular'><span className='symOccurence' style={{marginRight:'10px'}}>{el.occurance[1]} </span>  {el.winAmount[1]}</p>
                          <p className='bebas-neue-regular'><span className='symOccurence' style={{marginRight:'10px'}}>{el.occurance[0]} </span>  {el.winAmount[0]}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <div className='bottomCards2'>
                {
                  payoutApiData?.map((el) => {
                    return (
                      <div className='card' key={el.symbolGroupId}>
                        <div className='imgContainer'>
                          <img src={imgHandle(el.symbol)} alt='' />
                          <div className='cardText'>
                            <p className='bebas-neue-regular'><span className='symOccurence' style={{marginRight:'10px'}}>{el.occurance[2]} </span>  {el.winAmount[2]}</p>
                            <p className='bebas-neue-regular'><span className='symOccurence' style={{marginRight:'10px'}}>{el.occurance[1]} </span>  {el.winAmount[1]}</p>
                            <p className='bebas-neue-regular'><span className='symOccurence' style={{marginRight:'10px'}}>{el.occurance[0]} </span>  {el.winAmount[0]}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </div>
        </div>
        {/* payout Table page code ends here */}


        {/* Game Rules page code starts here */}
        <div className='bebas-neue-regular payoutGameRulesPage' style={{ width: "100%", height: "100vh", display:'flex', justifyContent:'center', alignItems:'start', textAlign:'center', paddingTop:'13vh' }}>
          <div className='rulesPage'>
          <h2>Rules</h2>
            <div className='ruleSection1'>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>Select credits per line to start betting.</p></div>
            <div className='ruleText bebas-neue-regular'><img src={Calavera} alt='' /><p>Substitute for all symbols except scatter symbols </p><img src={Piramide} alt='' /><p> & Symbols</p><img src={SunFire} alt='' /></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>A player can't get </p><img src={Calavera} alt='' /><p> on the first reel on base game.</p></div>
            <div className='ruleText bebas-neue-regular'><img src={Calavera} alt='' /><p>Can only appear on the second, third, fourth and fifth reel.</p></div>
            </div>
            <div className='ruleSection2'>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>On paid games, all wins begin from the left most reel and pay left to right on adjacent reels,</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>expect for scatter symbols </p><img src={Piramide} alt='' /></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>Scatter symbols</p> <img src={Piramide} alt='' /> <p>ca't from multiple lines or prizes.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>Scatter symbols </p><img src={Piramide} alt='' /> <p>can only appear once per reel.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>Scatter symbols </p><img src={Piramide} alt='' /><p> wins are added to the other win lines</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>All wins show only in highlighted lines, except scatter symbols</p> <img src={Piramide} alt='' /> <p>& symbols </p><img src={SunFire} alt='' /></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>Only the highest win on each line only the highest scatter win is paid.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>wins in different lines are added.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>All wins show in credits, except mini, minor & progressives.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>Grand and major prizes are progressive jackpots.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>Each line wins are multiplied by the credits betted per line.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>Malfunction voids all pays and games.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>The player is responsible of verifying if the credit is properly registered before starting to play.</p></div>
            </div>
          </div>
        </div>
        {/* Game Rules page code ends here */}

        {/* Free bonus games code starts here */}
        <div className='bebas-neue-regular' style={{ width: "100%", height: "100vh", display:'flex', justifyContent:'center', alignItems:'start', textAlign:'center', paddingTop:'15vh' }}>
        <div className='rulesPage'>
        <h2 style={{marginBottom:'20px'}}>Free Games Bonus</h2>
            <div className='ruleSection1'  style={{marginBottom:'10px'}}>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>5 free games are awarded by 3 or more scatter symbols </p><img src={Piramide} alt='' /></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>5</p><img src={Piramide} alt='' /> <p>Pays Bet X 100</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>4</p><img src={Piramide} alt='' /> <p>Pays Bet X 10</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>3</p><img src={Piramide} alt='' /> <p>Pays Bet X 2</p></div>
          
            </div>
            <div className='ruleSection2'>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>During the free games bonus, all of the symbols in the first & last reel will become wilds.</p></div>
            <div style={{marginBottom:'10px'}} className='ruleText bebas-neue-regular'><p className='rulesText'>During the free games bonus, 3 scatter symbols</p> <img src={Piramide} alt='' /> <p>will award 5 aditional free games.</p></div>
            <div style={{marginBottom:'10px'}} className='ruleText bebas-neue-regular'><p className='rulesText'>Credits betted are the same as the game that triggered the free games bouns.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>During the free games bonus, you can also trigger</p> <img src={LinkHit} alt='' /></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>If you get 6 or more symbols</p> <img src={SunFire} alt='' /> <p>during a free games bonus spin, it will trigger</p> <img src={LinkHit} alt='' /></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>AND at the end of the feature, the free games bonus will resume as normal.</p></div>
            </div>
          </div>
        </div>
        {/* Free bonus games code ends here */}
        {/* Paylines page code starts here */}
        <div className='rulesPage' style={{ width: "100%", height: "100vh", paddingTop:'18vh'}}>
        <h2 style={{textAlign:'center', marginBottom:'5vh'}}>Rules</h2>
          <div className='paylinesPage' style={{width:'80%', margin: '0 auto', display:'flex', flexWrap:'wrap'}}>
            {
              payLineApiData?.map((el, i)=> {
                return(<>
               <div style={{display:'flex', flexDirection:'column', width:'10%', padding:'4px'}}>
               <p style={{fontSize:'12px', fontWeight:'600', textAlign:'center'}}> Line {el}</p>
               <img src={`../../assets/showPayline/B${el}.png`} alt=''key = {i} />
               </div>
                </>)
              })
            }
            {/* <img src={`/assets/showPayline/b1.png`} alt=''/> */}
          </div>
        </div>
        {/* Paylines page code ends here */}
        {/* LinkHit page code Starts here */}
        <div className='bebas-neue-regular' style={{ width: "100%", height: "100vh", display:'flex', justifyContent:'center', alignItems:'start', textAlign:'center', paddingTop:'16vh' }}>
        <div className='rulesPage'>
        <img style={{width:'30%', height:'auto'}} src={FoF} alt='' />
            <div className='ruleSection1'>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>Six or more symbols </p><img src={SunFire} alt='' /> <p>in the base game can trigger 3 spins of</p> <img src={LinkHit} alt='' /></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>The player will first receive the payment for the lines in the initial spin, and then 3 re-spins of</p> <img src={LinkHit} alt='' /> <p>will start.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>All symbols </p><img src={SunFire} alt='' /> <p>show a credit prize or any of the jackpots mini/minor/major.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>The credit prize in each symbol</p> <img src={SunFire} alt='' /> <p>may contain a prize between 1 and 100 times the bet.</p></div>
            </div>
            <div className='ruleSection2'>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>Each time you get one or more symbols</p> <img src={SunFire} alt='' /> <p>, the number of remaining spins will reset to 3.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>The feature of </p><img src={LinkHit} alt='' /> <p>ends when the player has no remaining spins or when the grid is full.</p></div>
            <div className='ruleText bebas-neue-regular'><p className='rulesText'>The grand jackpot is awarded when the player gets to fill the 15 spaces of the grid with the symbols</p> <img src={SunFire} alt='' /><p>.</p></div>

            <div className='ruleText bebas-neue-regular'><p className='rulesText'>In case of award 2 or more major jackpots in</p> <img src={LinkHit} alt='' /> <p>only the first one will be paid with the progressive quantity </p></div>
            <div className='ruleText bebas-neue-regular'><p>the rest ones will pay only the base prize.</p>
            </div>
            </div>
          </div>
        </div>
        {/* LinkHit page code ends here */}
        {/* Buttons page code starts here */}
        <div className='bebas-neue-regular' style={{ width: "100%", height: "100vh", display:'flex', justifyContent:'center', alignItems:'start', textAlign:'center', paddingTop:'16vh' }}>
          <div className='buttonsPage' style={{width:'60%'}}>
          <h2>Buttons</h2>
            <div className='btnRow'>
            <div className='btnImg'><img src={btnCircle} alt='' /> <span>Menu</span></div>
            <div className='btnText'><p>Click this button to return to the game selection screen.</p></div>
            </div>
            <div className='btnRow'>
            <div className='btnImg'><img src={btnCircle} alt='' /> <span>5C</span></div>
            <div className='btnText'><p>Click to change the denominations available in the game.</p></div>
            </div>
            <div className='btnRow'>
            <div className='btnImg'><img src={btnInfo} alt='' /> <span>Info</span></div>
            <div className='btnText'><p>Click to view paytables and game rules.</p></div>
            </div>
            <div className='btnRow' >
            <div className='btnImg' style={{position:'relative'}}><img style={{height:"auto", width:'80%'}} src={btnBet} alt='' /><span style={{position:'absolute', top:'-5%', left:'50%', transform:'translate(-50%, 0)', fontSize:'16px', color:'#fff', textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'}}>Bet</span> <span style={{position:'absolute', color:'#f7d26a'}}>50</span></div>
            <div className='btnText'><p>Click to change the bet multiplier value.</p></div>
            </div>
            <div className='btnRow'>
            <div className='btnImg'><img src={btnCircle} alt='' /> <span style={{fontSize:'12px'}}>Cash<br/>Out</span></div>
            <div className='btnText'><p>Click to collect the current credits.</p></div>
            </div>
          </div>
        </div>

        {/* Buttons page code ends here */}
      </Slider>
      <img style={{position:'absolute', bottom:'-2%', height:'10vw', cursor:'pointer'}} src={ReturnButton} alt='' onClick={props.onClick}/>  
    </>
  )
}

export default SliderItems


// {
//   "symbol": "CC",
//   "iconName": "Exploradora_Simbolo",
//   "occurance": [
//       3,
//       4,
//       5
//   ],
//   "winAmount": [
//       25,
//       50,
//       200
//   ],
//   "symbolGroupId": 1
// }