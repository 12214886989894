//Creating PreloaderView

import React from 'react'

const Preloader = ({ bgcolor, progress, height, loader, logo }) => {
    const Parentdiv = {
        position: "absolute",
        top: "50%",
        left: "50%",
        height: height,
        width: '50%',
        backgroundColor: 'whitesmoke',
        borderRadius: 40,
        transform: "translate(-50%,-50%)",
        border:'5px solid #c56600'
    }

    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
        borderRadius: 40,
        textAlign: 'right'
    }

    const progresstext = {
        padding: 10,
        color: progress== 100 ? '#fff' : '#000',
        fontWeight: 900
    }
    return (
        <div style={{
            position: "absolute",
            background: "#000",
            top: "0",
            left: "0",
            // transform: "translate(-50%, -50%)",
            display: (!loader) ? "block" : "none",
            height: "100vh",
            width: "100vw"
        }}>
            <div style={Parentdiv}>
                <div style={Childdiv}>
                    <span style={progresstext}>{`${progress}%`}</span>
                </div>
            </div>
            <div>
                <img
                    src={logo}
                    alt="loader"
                    style={{
                        position: "absolute",
                        background: "#00000000",
                        top: "20%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "30%"
                    }}
                />
            </div>
        </div>

    )
}

export default Preloader;
