import React, { useEffect, useRef, useState } from "react";
import Loader from "../assets/loader-animation.gif";
import Foff from "../assets/FoF-Logo.png";
import * as PIXI from "pixi.js"
import { toast, ToastContainer } from "react-toastify";
import {
  Application,
  Assets,
  Color,
  Container,
  Texture,
  Sprite,
  Graphics,
  Text,
  TextStyle,
  BlurFilter,
  FillGradient,
  Rectangle,
  AnimatedSprite
} from "pixi.js";
import axios from "axios";
import GameHistoryTable from "../Pages/GameHistoryTable";
import SliderItems from "./Payout Table Slider/SliderItems";
import SessionExpired from "./SessionExpired";
import { useNavigate } from "react-router-dom";
import LoadFile from "./LoadFile.json"


import Preloader from "./Preloader";

const Slot = () => {
  const pixiContainer = useRef(null);
  const [isHtmlVisible, setHtmlVisible] = useState(false);
  const [isPayoutTable, setPayoutTable] = useState(false);
  const [isSessionExpired, setSessionExpired] = useState(false);
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();
  const playerId = localStorage.getItem("playerId");
  const token = localStorage.getItem("token");

  useEffect(() => {


    (async () => {

      // Create a new application
      const app = new Application();

      // Initialize the application
      await app.init({ resizeTo: window });

      // Append the application canvas to the document body
      document.body.appendChild(app.canvas);

      // Load the textures
      await Assets.load(LoadFile.loadImages);



      Assets.addBundle('fonts', LoadFile.textStyle);
      await Assets.loadBundle('fonts').then(() => {
        // console.log('font Loaded');
      });

      var size = [window.innerWidth, window.innerHeight];
      var ratio = size[0] / size[1];
      let X1percent = size[0] / 100;
      let Y1percent = size[1] / 100;

      const REEL_WIDTH = app.screen.width / 8;
      const SYMBOL_SIZE = app.screen.width / 9;

      //loading Symbol List
      const slotTextures = [];
      const symbolList = LoadFile?.symbolsList;
      for (let i = 0; i < symbolList?.length; i++) {
        slotTextures.push(Texture.from(symbolList[i]));
      }

      slotTextures[0].name = "HH";
      slotTextures[1].name = "KK";
      slotTextures[2].name = "II";
      slotTextures[3].name = "FF";
      slotTextures[4].name = "WW";
      slotTextures[5].name = "CC";
      slotTextures[6].name = "DD";
      slotTextures[7].name = "GG";
      slotTextures[8].name = "SS";
      slotTextures[9].name = "JJ";
      slotTextures[10].name = "EE";
      slotTextures[11].name = "TT";

      const sheetSheet = await Assets.load("/assets/SpriteSheet.json");
      const sheetSheet2 = await Assets.load("/assets/SunLoop_Sheet02.json");

      const spriteA00 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB00 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA01 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB01 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA02 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB02 = new AnimatedSprite(sheetSheet2.animations.enemy);

      const spriteA10 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB10 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA11 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB11 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA12 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB12 = new AnimatedSprite(sheetSheet2.animations.enemy);

      const spriteA20 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB20 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA21 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB21 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA22 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB22 = new AnimatedSprite(sheetSheet2.animations.enemy);

      const spriteA30 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB30 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA31 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB31 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA32 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB32 = new AnimatedSprite(sheetSheet2.animations.enemy);

      const spriteA40 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB40 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA41 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB41 = new AnimatedSprite(sheetSheet2.animations.enemy);
      const spriteA42 = new AnimatedSprite(sheetSheet.animations.enemy);
      const spriteB42 = new AnimatedSprite(sheetSheet2.animations.enemy);

      function animateSpin() {
        spriteA00.anchor.set(0.5);
        spriteA00.animationSpeed = 1.5;
        spriteA00.loop = false;
        spriteA00.play();
        spriteB00.anchor.set(0.5);
        spriteB00.animationSpeed = 1.5;
        spriteB00.animationSpeed = 1.5;
        spriteA00.width = app.screen.width / 10;
        spriteA00.height = app.screen.height / 5;
        spriteB00.width = app.screen.width / 4.45;
        spriteB00.height = app.screen.height / 2.22;
        spriteB00.play();
        spriteA00.addChild(spriteB00);
        app.stage.addChildAt(spriteA00, 20);
      }

      function animateSpin1() {
        spriteA01.anchor.set(0.5);
        spriteA01.animationSpeed = 1.5;
        spriteA01.loop = false;
        spriteA01.play();
        spriteB01.anchor.set(0.5);
        spriteB01.animationSpeed = 1.5;
        spriteB01.animationSpeed = 1.5;
        spriteA01.width = app.screen.width / 10;
        spriteA01.height = app.screen.height / 5;
        spriteB01.width = app.screen.width / 4.45;
        spriteB01.height = app.screen.height / 2.22;
        spriteB01.play();

        spriteA01.addChild(spriteB01);
        app.stage.addChildAt(spriteA01, 20);
      }

      function animateSpin2() {
        spriteA02.anchor.set(0.5);
        spriteA02.animationSpeed = 1.5;
        spriteA02.loop = false;
        spriteA02.play();
        spriteB02.anchor.set(0.5);
        spriteB02.animationSpeed = 1.5;
        spriteB02.animationSpeed = 1.5;
        spriteA02.width = app.screen.width / 10;
        spriteA02.height = app.screen.height / 5;
        spriteB02.width = app.screen.width / 4.45;
        spriteB02.height = app.screen.height / 2.22;
        spriteB02.play();

        spriteA02.addChild(spriteB02);
        app.stage.addChildAt(spriteA02, 20);
      }

      function animateSpin3() {
        spriteA10.width = app.screen.width / 10;
        spriteA10.height = app.screen.height / 5;
        spriteB10.width = app.screen.width / 4.45;
        spriteB10.height = app.screen.height / 2.22;
        spriteA10.anchor.set(0.5);
        spriteA10.animationSpeed = 1.5;
        spriteA10.loop = false;
        spriteA10.play();
        spriteB10.anchor.set(0.5);
        spriteB10.animationSpeed = 1.5;
        spriteB10.animationSpeed = 1.5;
        spriteB10.play();

        spriteA10.addChild(spriteB10);
        app.stage.addChildAt(spriteA10, 20);
      }

      function animateSpin4() {
        spriteA11.width = app.screen.width / 10;
        spriteA11.height = app.screen.height / 5;
        spriteB11.width = app.screen.width / 4.45;
        spriteB11.height = app.screen.height / 2.22;
        spriteA11.anchor.set(0.5);
        spriteA11.animationSpeed = 1.5;
        spriteA11.loop = false;
        spriteA11.play();
        spriteB11.anchor.set(0.5);
        spriteB11.animationSpeed = 1.5;
        spriteB11.animationSpeed = 1.5;
        spriteB11.play();

        spriteA11.addChild(spriteB11);
        app.stage.addChildAt(spriteA11, 20);
      }

      function animateSpin5() {
        spriteA12.width = app.screen.width / 10;
        spriteA12.height = app.screen.height / 5;
        spriteB12.width = app.screen.width / 4.45;
        spriteB12.height = app.screen.height / 2.22;
        spriteA12.anchor.set(0.5);
        spriteA12.animationSpeed = 1.5;
        spriteA12.loop = false;
        spriteA12.play();
        spriteB12.anchor.set(0.5);
        spriteB12.animationSpeed = 1.5;
        spriteB12.animationSpeed = 1.5;
        spriteB12.play();

        spriteA12.addChild(spriteB12);
        app.stage.addChildAt(spriteA12, 20);
      }

      function animateSpin6() {
        spriteA20.height = app.screen.height / 5;
        spriteA20.width = app.screen.width / 10;
        spriteB20.width = app.screen.width / 4.45;
        spriteB20.height = app.screen.height / 2.22;
        spriteA20.anchor.set(0.5);
        spriteA20.animationSpeed = 1.5;
        spriteA20.loop = false;
        spriteA20.play();
        spriteB20.anchor.set(0.5);
        spriteB20.animationSpeed = 1.5;
        spriteB20.animationSpeed = 1.5;
        spriteB20.play();

        spriteA20.addChild(spriteB20);
        app.stage.addChildAt(spriteA20, 20);
      }

      function animateSpin7() {
        spriteA21.width = app.screen.width / 10;
        spriteA21.height = app.screen.height / 5;
        spriteB21.width = app.screen.width / 4.45;
        spriteB21.height = app.screen.height / 2.22;
        spriteA21.anchor.set(0.5);
        spriteA21.animationSpeed = 1.5;
        spriteA21.loop = false;
        spriteA21.play();
        spriteB21.anchor.set(0.5);
        spriteB21.animationSpeed = 1.5;
        spriteB21.animationSpeed = 1.5;
        spriteB21.play();

        spriteA21.addChild(spriteB21);
        app.stage.addChildAt(spriteA21, 20);
      }

      function animateSpin8() {
        spriteA22.width = app.screen.width / 10;
        spriteA22.height = app.screen.height / 5;
        spriteB22.width = app.screen.width / 4.45;
        spriteB22.height = app.screen.height / 2.22;
        spriteA22.anchor.set(0.5);
        spriteA22.animationSpeed = 1.5;
        spriteA22.loop = false;
        spriteA22.play();
        spriteB22.anchor.set(0.5);
        spriteB22.animationSpeed = 1.5;
        spriteB22.animationSpeed = 1.5;
        spriteB22.play();

        spriteA22.addChild(spriteB22);
        app.stage.addChildAt(spriteA22, 20);
      }

      function animateSpin9() {
        spriteA30.width = app.screen.width / 10;
        spriteA30.height = app.screen.height / 5;
        spriteB30.width = app.screen.width / 4.45;
        spriteB30.height = app.screen.height / 2.22;
        spriteA30.anchor.set(0.5);
        spriteA30.animationSpeed = 1.5;
        spriteA30.loop = false;
        spriteA30.play();
        spriteB30.anchor.set(0.5);
        spriteB30.animationSpeed = 1.5;
        spriteB30.animationSpeed = 1.5;
        spriteB30.play();

        spriteA30.addChild(spriteB30);
        app.stage.addChildAt(spriteA30, 20);
      }

      function animateSpin10() {
        spriteA31.height = app.screen.height / 5;
        spriteA31.width = app.screen.width / 10;
        spriteB31.width = app.screen.width / 4.45;
        spriteB31.height = app.screen.height / 2.22;
        spriteA31.anchor.set(0.5);
        spriteA31.animationSpeed = 1.5;
        spriteA31.loop = false;
        spriteA31.play();
        spriteB31.anchor.set(0.5);
        spriteB31.animationSpeed = 1.5;
        spriteB31.animationSpeed = 1.5;
        spriteB31.play();

        spriteA31.addChild(spriteB31);
        app.stage.addChildAt(spriteA31, 20);
      }

      function animateSpin11() {
        spriteA32.width = app.screen.width / 10;
        spriteA32.height = app.screen.height / 5;
        spriteB32.width = app.screen.width / 4.45;
        spriteB32.height = app.screen.height / 2.22;
        spriteA32.anchor.set(0.5);
        spriteA32.animationSpeed = 1.5;
        spriteA32.loop = false;
        spriteA32.play();
        spriteB32.anchor.set(0.5);
        spriteB32.animationSpeed = 1.5;
        spriteB32.animationSpeed = 1.5;
        spriteB32.play();

        spriteA32.addChild(spriteB32);
        app.stage.addChildAt(spriteA32, 20);
      }

      function animateSpin12() {
        spriteA40.width = app.screen.width / 10;
        spriteA40.height = app.screen.height / 5;
        spriteB40.width = app.screen.width / 4.45;
        spriteB40.height = app.screen.height / 2.22;
        spriteA40.anchor.set(0.5);
        spriteA40.animationSpeed = 1.5;
        spriteA40.loop = false;
        spriteA40.play();
        spriteB40.anchor.set(0.5);
        spriteB40.animationSpeed = 1.5;
        spriteB40.animationSpeed = 1.5;
        spriteB40.play();

        spriteA40.addChild(spriteB40);
        app.stage.addChildAt(spriteA40, 20);
      }

      function animateSpin13() {
        spriteA41.width = app.screen.width / 10;
        spriteA41.height = app.screen.height / 5;
        spriteB41.width = app.screen.width / 4.45;
        spriteB41.height = app.screen.height / 2.22;
        spriteA41.anchor.set(0.5);
        spriteA41.animationSpeed = 1.5;
        spriteA41.loop = false;
        spriteA41.play();
        spriteB41.anchor.set(0.5);
        spriteB41.animationSpeed = 1.5;
        spriteB41.animationSpeed = 1.5;
        spriteB41.play();

        spriteA41.addChild(spriteB41);
        app.stage.addChildAt(spriteA41, 20);
      }

      function animateSpin14() {
        spriteA42.width = app.screen.width / 10;
        spriteA42.height = app.screen.height / 5;
        spriteB42.width = app.screen.width / 4.45;
        spriteB42.height = app.screen.height / 2.22;
        spriteA42.anchor.set(0.5);
        spriteA42.animationSpeed = 1.5;
        spriteA42.loop = false;
        spriteA42.play();
        spriteB42.anchor.set(0.5);
        spriteB42.animationSpeed = 1.5;
        spriteB42.animationSpeed = 1.5;
        spriteB42.play();

        spriteA42.addChild(spriteB42);
        app.stage.addChildAt(spriteA42, 20);
      }



      //assets Loaded
      setTimeout(() => {
        setLoder(!loder);
      }, 1000);
      setProgress(100);


      var creditsVal;
      var winCount;
      var freeSpin;
      var currentfreeSpinGameLoad;
      var currentfreeSpin;
      var winValue = 0;
      var winAmountShow


      async function gameLoadDataApi() {
        try {
          const gameData = await axios?.post(
            `${process.env.REACT_APP_API_URL}/GamePlay/GameLoad`,
            {
              playerId: playerId,
              gameId: 0
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
          // console.log(gameData, "gameData--gameData");

          return gameData?.data?.resultData;
        } catch (error) {
          if (error?.response?.status === 401) {
            localStorage.removeItem("playerId");
            localStorage.removeItem("token");
            sessionExpired()
          }

        }
      }

      function sessionExpired() {
        setSessionExpired(!isSessionExpired)
      }
      let data;

      try {
        data = await gameLoadDataApi();
        creditsVal = data?.playerBalance || 0;
        currentfreeSpinGameLoad = data?.noOfRemaningFreeSpin || 0
        // console.log(await gameLoadDataApi(), "gameData-");
      } catch (error) {
        // console.log(error);
      }
      // console.log(data, "gameData-1");



      //noOfRemaningFreeSpin
      let reelData = data?.defaultCombination?.split(",");
      // console.log(reelData, "reelData");

      var singlePaylinesNum = [];
      var paylinesNum;
      //  game spin api function
      var imgArrSlot = [];
      var startCount = 0;
      var payLineIntervel;

      var coinDenonimation = 1;
      // var betAmountValue = 50;

      var betVal = 50;
      var payLineAnimateArr;
      var winningSymbolArr;
      var occurenceArr;
      function calculateOutput(value) {
        if (value >= 500) {
          return "10.00";
        } else if (value >= 250) {
          return "5.00";
        } else if (value >= 150) {
          return "3.00";
        } else if (value >= 100) {
          return "2.00";
        } else if (value >= 50) {
          return "1.00";
        }
      }

      async function gameSpinApi(spinType) {

        payLineTrueHandle()

        var betAmountNewVal = calculateOutput(betVal);
        try {

          const dataApi = await axios?.post(
            `${process.env.REACT_APP_API_URL}/GamePlay/Bet/Bet`,
            {
              gameId: 0,
              playerId: playerId,
              denomination: (coinDenonimation / 100).toFixed(2), // 0.01, 0.02, 0.05, 0.10, 0.25
              betAmount: betAmountNewVal, // 1.00, 2.00, 3.00  5.00  10.00  
              multiplier: 1,
              isfreeSpinWon: spinType
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );

          winCount = dataApi?.data?.resultData?.winValue || 0;
          freeSpin = dataApi?.data?.resultData?.remaningFreeSpinCount || 0;
          currentfreeSpin = dataApi?.data?.resultData?.noOfFreeSpinWon || 0;
          if (currentfreeSpin > 0) {
            noOfFreeSpinWonHandle()
          } else {

          }


          // console.log(winCount, "dataNewApi");
          let newSlotArr =
            dataApi?.data?.resultData?.winningCombination?.split(",");

          creditsVal = dataApi?.data?.resultData?.playerCurrentBalance;

          winValue = dataApi?.data?.resultData?.winValue;

          // console.log(winValue, 'winValue=====winValue')
          // updateCredits(creditsVal/coinDenonimation);
          updateCredits(Math.floor(creditsVal / coinDenonimation));
          updateCreditsSubVal(creditsVal)
          // console.log(creditsVal, "updatedCreditval");

          setTimeout(() => {
            UpdateWinValue(winValue);
          }, 1000);

          function getImgWithUrl(name) {
            return slotTextures.filter((el, i) => (el.name === name ? el : ""));
          }
          imgArrSlot = [];
          for (let i = 0; i < newSlotArr.length; i++) {
            imgArrSlot.push(getImgWithUrl(newSlotArr[i])[0]);
          }
          // console.log(imgArrSlot, "imgArrSlot imgArrSlot imgArrSlot--12345"); //slotTextures2
          if (
            dataApi?.data?.resultData?.winValue > 0 &&
            dataApi?.data?.resultData?.winningAtPayLines !== " "
          ) {
            winAmountShow = dataApi?.data?.resultData?.winningByPaylines?.split(",");
            paylinesNum =
              dataApi?.data?.resultData?.winningAtPayLines?.split(",");
            let payLineAnimate = dataApi?.data?.resultData?.winningIndexes;
            payLineAnimateArr = payLineAnimate
              .split("/")
              .map((row) => row.split(" ").map(Number));
            // console.log(payLineAnimateArr, 'payLineAnimateArr--- payLineAnimateArr')
            winningSymbolArr =
              dataApi?.data?.resultData?.winingSymbol?.split(",");

            occurenceArr = dataApi?.data?.resultData?.symbolOccurrence.split(",")

            createLinesBunch_No(paylinesNum);
            setTimeout(() => {
              singlePaylinesNum = [...singlePaylinesNum, paylinesNum];
            });
            // console.log(paylinesNum, "paylinesNumpaylinesNum");
            // createLines1(); noOfFreeSpinWon

          } else {
          }


          // startPlay()
          return imgArrSlot;
        } catch (error) {
          console.error("Error:", error?.response?.data);
        }
      }

      // console.log(data, "gameData-1");

      // *****************************  get sprite sheet function by name  *********************************//

      let slotSpriteSheet = [
        "/assets/Exploradora_SpriteSheet.json", //CC
        "/assets/Torch_SpriteSheet.json", //FF
        "/assets/Skull_SpriteSheet.json", // WW
        "/assets/Quetzal_SpriteSheet.json", //EE
        "/assets/Piramide_SpriteSheet.json", // SS
        "/assets/Moneda_SpriteSheet.json", //GG
        "/assets/Gema_SpriteSheet.json" // DD
      ];

      // Load all the sprite sheet JSON files
      Promise.all(slotSpriteSheet.map(url => Assets.load(url))).then(textures => {
        // console.log("All sprite sheets loaded successfully!");
      }).catch(error => {
        console.error("Error loading sprite sheets:", error);
      });




      let slotNameArr = ["CC", "FF", "WW", "EE", "SS", "GG", "DD"];

      function getSpriteSheet(name) {
        // return slotSpriteSheet[slotNameArr.indexOf(name)];
        return slotSpriteSheet[slotNameArr.indexOf(name)]
          ? slotSpriteSheet[slotNameArr.indexOf(name)]
          : "PP";
      }

      //// 00000000000000000 4
      var removeAnimSprite = [];
      async function animateSpinSprite(img, x, y) {
        let name = await getSpriteSheet(img);
        let sheetSheet1 = await Assets.load(name);
        let spriteSheet = new AnimatedSprite(sheetSheet1.animations.enemy);
        spriteSheet.width = app.screen.width / 10;
        spriteSheet.height = app.screen.height / 5;
        spriteSheet.anchor.set(0.5);
        spriteSheet.animationSpeed = 1.5;
        spriteSheet.loop = true;
        spriteSheet.play();
        spriteSheet.y = app.screen.height / y;
        spriteSheet.x = app.screen.width / x;
        app.stage.addChildAt(spriteSheet, 30);
        removeAnimSprite.push(spriteSheet);
      }

      function removeAllSprites() {
        for (let sprite of removeAnimSprite) {
          app.stage.removeChild(sprite);
          sprite.destroy(); // Clean up resources
        }
        removeAnimSprite.length = 0; // Clear the array
      }

      //***************************************************************** */

      const BackgroundImg = new Sprite(
        Texture.from("/assets/MainBG/Dorado_Main_BG.png")
      );
      BackgroundImg.height = app.screen.height;
      BackgroundImg.width = app.screen.width;
      BackgroundImg.x = (app.screen.width - BackgroundImg.width) / 2;
      BackgroundImg.y = (app.screen.height - BackgroundImg.height) / 2;

      app.stage.addChild(BackgroundImg);

      // bet betVal

      //  const MarginY = 0;
      const MarginY = 0;
      // const MarginY = (app.screen.height - SYMBOL_SIZE * 3) / 2

      // Game Frame Image added here
      const GameFrame = new Sprite(
        Texture.from("/assets/Game/Dorado_frame.png")
      );
      GameFrame.height = app.screen.height / 1.5;
      GameFrame.width = app.screen.width / 1.55;
      GameFrame.x = (app.screen.width - GameFrame.width) / 2; // Center horizontally
      GameFrame.y = (app.screen.height - GameFrame.height) / 2; // Center horizontally
      app.stage.addChild(GameFrame);
      // console.log(GameFrame.width, "GameFrame.width");
      // console.log(GameFrame.height, "GameFrame.height");

      // Game Frame background
      const GameBG = new Sprite(
        Texture.from("/assets/MainBG/Dorado_background_Game.png")
      );
      GameBG.height = app.screen.height / 1.62;
      GameBG.width = ((app.screen.width - GameFrame.width) * 3.48) / 2;
      GameBG.x = (app.screen.width - GameBG.width) / 2;
      GameBG.y = (app.screen.height - GameBG.height) / 2;

      app.stage.addChild(GameBG);

      // Add play text
      const textStyle = new TextStyle({
        fontSize: betVal === 500 ? app.screen.width / 98 : app.screen.width / 54,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });



      // Add play text
      const coinDenomTextStyle = new TextStyle({
        fontSize: window.innerWidth > 1440 ? app.screen.width / 50 : app.screen.width / 54,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });

      const top = new Graphics()
        .rect(0, 0, app.screen.width, MarginY)
        .fill({ color: 0x0 });
      const bottom = new Graphics()
        .rect(0, 200 * 3 + MarginY, app.screen.width, MarginY)
        .fill({ color: 0x0 });

      //  ******************* Game Top Section Code*********************

      ////***********   Minor text Section starts  *************
      //Minor Text Frame background Image imported here
      const MinorTextFrameBG = new Sprite(
        Texture.from("/assets/JackPotsLinks/Minor_Bg.png")
      );
      MinorTextFrameBG.height = app.screen.height / 11.5;
      MinorTextFrameBG.width = app.screen.width / 9.5;
      MinorTextFrameBG.x = (app.screen.width - MinorTextFrameBG.width) / 7;
      MinorTextFrameBG.y = (app.screen.height - MinorTextFrameBG.height) / 19;
      app.stage.addChild(MinorTextFrameBG);

      //Minor Text Frame imported here
      const MinorTextFrame = new Sprite(
        Texture.from("/assets/JackPotsLinks/Minor_Frame.png")
      );
      MinorTextFrame.height = app.screen.height / 9;
      MinorTextFrame.width = app.screen.width / 9;
      MinorTextFrame.x = (app.screen.width - MinorTextFrame.width) / 7;
      MinorTextFrame.y = (app.screen.height - MinorTextFrame.height) / 30;
      app.stage.addChild(MinorTextFrame);

      //Minor Text Png imported here
      const MinorTextImg = new Sprite(
        Texture.from("/assets/JackPotsLinks/Minor_TxT.png")
      );
      MinorTextImg.height = app.screen.height / 20;
      MinorTextImg.width = app.screen.width / 18;
      MinorTextImg.x = (app.screen.width - MinorTextImg.width) / 6.1;
      MinorTextImg.y = (app.screen.height - MinorTextImg.height) / 40;
      app.stage.addChild(MinorTextImg);

      // Minor Text added here
      const MinorText = new Text(`$${betVal}.00`, textStyle);
      // MinorText.width = app.screen.width / 20;
      // console.log(window.innerWidth, 'moirorText')
      MinorText.width = window.innerWidth > 1440 ? app.screen.width / 17.5 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? app.screen.width / 35 : (window.innerWidth > 800 && window.innerWidth < 1367) ? app.screen.width / 28 : app.screen.width / 50;
      MinorText.x = (app.screen.width - MinorText.width) / 6.1;
      MinorText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(MinorText);

      ////***************   Grand text Section strats   ***************
      //Grand Text Frame background Image imported here
      const GrandTextFrameBG = new Sprite(
        Texture.from("/assets/JackPotsLinks/Grand_Bg.png")
      );
      GrandTextFrameBG.height = app.screen.height / 8.5;
      GrandTextFrameBG.width = app.screen.width / 5.8;
      GrandTextFrameBG.x = (app.screen.width - GrandTextFrameBG.width) / 2.65;
      GrandTextFrameBG.y = (app.screen.height - GrandTextFrameBG.height) / 25;
      app.stage.addChild(GrandTextFrameBG);

      //Grand Text Frame imported here
      const GrandTextFrame = new Sprite(
        Texture.from("/assets/JackPotsLinks/Grand_Frame.png")
      );
      GrandTextFrame.height = app.screen.height / 7.5;
      GrandTextFrame.width = app.screen.width / 5;
      GrandTextFrame.x = (app.screen.width - GrandTextFrame.width) / 2.7;
      GrandTextFrame.y = (app.screen.height - GrandTextFrame.height) / 30;
      app.stage.addChild(GrandTextFrame);

      //Grand Text Frame imported here
      const GrandTextImg = new Sprite(
        Texture.from("/assets/JackPotsLinks/Grand_TxT.png")
      );
      GrandTextImg.height = app.screen.height / 20;
      GrandTextImg.width = app.screen.width / 16;
      GrandTextImg.x = (app.screen.width - GrandTextImg.width) / 2.55;
      GrandTextImg.y = (app.screen.height - GrandTextImg.height) / 100;
      app.stage.addChild(GrandTextImg);

      // Add Grand text
      const GrandText = new Text("$100,000.00", textStyle);
      GrandText.width = app.screen.width / 9;
      GrandText.x = window.innerWidth > 1600 ? (app.screen.width - GrandTextImg.width) / 2.7 : window.innerWidth > 1367 && window.innerWidth < 1599 ? (app.screen.width - GrandTextImg.width) / 2.75 : (app.screen.width - GrandTextImg.width) / 2.7;
      GrandText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(GrandText);
      app.stage.addChild(top);

      ////***************   Major text Section starts   ***************
      //Major Text Frame background Image imported here
      const MajorTextFrameBG = new Sprite(
        Texture.from("/assets/JackPotsLinks/Major_Bg.png")
      );
      MajorTextFrameBG.height = app.screen.height / 8.5;
      MajorTextFrameBG.width = app.screen.width / 5.21;
      MajorTextFrameBG.x = (app.screen.width - MajorTextFrameBG.width) / 1.609;
      MajorTextFrameBG.y = (app.screen.height - MajorTextFrameBG.height) / 25;
      app.stage.addChild(MajorTextFrameBG);

      //Grand Text Frame imported here
      const MajorTextFrame = new Sprite(
        Texture.from("/assets/JackPotsLinks/Major_Frame.png")
      );
      MajorTextFrame.height = app.screen.height / 7.4;
      MajorTextFrame.width = app.screen.width / 5;
      MajorTextFrame.x = (app.screen.width - MajorTextFrame.width) / 1.6;
      MajorTextFrame.y = (app.screen.height - MajorTextFrame.height) / 40;
      app.stage.addChild(MajorTextFrame);

      //Major Text Frame imported here
      const MajorTextImg = new Sprite(
        Texture.from("/assets/JackPotsLinks/Major_TxT.png")
      );
      MajorTextImg.height = app.screen.height / 18;
      MajorTextImg.width = app.screen.width / 15;
      MajorTextImg.x = (app.screen.width - MajorTextImg.width) / 1.65;
      MajorTextImg.y = (app.screen.height - MajorTextImg.height) / 100;
      app.stage.addChild(MajorTextImg);

      // Add Major text
      const MajorText = new Text("$10,000.00", textStyle);
      MajorText.width = app.screen.width / 8;
      MajorText.x = window.innerWidth > 1440 ? (app.screen.width - MajorTextImg.width) / 1.72 : (app.screen.width - MajorTextImg.width) / 1.75;
      MajorText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(MajorText);
      app.stage.addChild(top);

      ////***************   Mini text section starts   ***************
      //Mini Text Frame background Image imported here
      const MiniTextFrameBG = new Sprite(
        Texture.from("/assets/JackPotsLinks/Mini_Bg.png")
      );
      MiniTextFrameBG.height = app.screen.height / 11.5;
      MiniTextFrameBG.width = app.screen.width / 11.5;
      MiniTextFrameBG.x = (app.screen.width - MiniTextFrameBG.width) / 1.186;
      MiniTextFrameBG.y = (app.screen.height - MiniTextFrameBG.height) / 19;
      app.stage.addChild(MiniTextFrameBG);

      //Mini Text Frame imported here
      const MiniTextFrame = new Sprite(
        Texture.from("/assets/JackPotsLinks/Mini_Frame.png")
      );
      MiniTextFrame.height = app.screen.height / 9;
      MiniTextFrame.width = app.screen.width / 9;
      MiniTextFrame.x = (app.screen.width - MiniTextFrame.width) / 1.18;
      MiniTextFrame.y = (app.screen.height - MiniTextFrame.height) / 30;
      app.stage.addChild(MiniTextFrame);

      //Mini Text Png imported here
      const MiniTextImg = new Sprite(
        Texture.from("/assets/JackPotsLinks/Mini_TxT.png")
      );
      MiniTextImg.height = app.screen.height / 20;
      MiniTextImg.width = app.screen.width / 18;
      MiniTextImg.x = (app.screen.width - MiniTextImg.width) / 1.2;
      MiniTextImg.y = (app.screen.height - MiniTextImg.height) / 40;
      app.stage.addChild(MiniTextImg);

      // Add Mini text
      const MiniText = new Text(`$${10}.00`, textStyle);
      // MiniText.width = app.screen.width / 20;
      MiniText.width = window.innerWidth > 1440 ? app.screen.width / 17.5 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? app.screen.width / 35 : (window.innerWidth > 800 && window.innerWidth < 1367) ? app.screen.width / 30 : app.screen.width / 50;
      MinorText.x = (app.screen.width - MinorText.width) / 6.15;
      MiniText.x = (app.screen.width - GrandTextImg.width) / 1.185;
      MiniText.y = (app.screen.height - MinorTextImg.height) / 12.5;
      top.addChild(MiniText);

      //  ******************* Game Bottom Section Code*********************

      // ********* Win Image imported
      const WinBg = new Sprite(Texture.from("/assets/Backgrounds/Hud_Won.png"));
      WinBg.height = app.screen.height / 7;
      WinBg.width = app.screen.width / 5;
      WinBg.x = (app.screen.width - WinBg.width) / 2;
      WinBg.y = (app.screen.height - WinBg.height) / 1;
      app.stage.addChild(WinBg);

      //bottom section text style starts
      const betTextStyle = new TextStyle({
        fontSize: app.screen.width / 55,
        fontWeight: 100,
        align: "center",
        fill: "#fff",
        fontFamily: "Arial , roboto, sans-serif",
        stroke: { color: "#000", width: 4 },
        wordWrap: true,
        wordWrapWidth: 440
      });

      const betSubTextStyle = new TextStyle({
        align: "center",
        fontSize: app.screen.width / 80,
        fontWeight: 100,
        fill: "#00b8b7",
        fontFamily: "Arial , roboto, sans-serif",
        stroke: { color: "#000", width: 4 },
        wordWrap: true,
        wordWrapWidth: 440
      });
      // ********* Credit Image imported
      const CreditsBg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_credits.png")
      );
      CreditsBg.height = app.screen.height / 7;
      CreditsBg.width = app.screen.width / 7;
      CreditsBg.x = (app.screen.width - CreditsBg.width) / 3.4;
      CreditsBg.y = (app.screen.height - CreditsBg.height) / 1;
      app.stage.addChild(CreditsBg);

      // Circular Image/Denomination text added
      const CreditText = new Text("CREDITS", betTextStyle);
      CreditText.x = (app.screen.width - CreditText.width) / 3.25;
      CreditText.y = (app.screen.height - CreditsBg.height) / 1.02;
      app.stage.addChild(CreditText);

      // Credit text code starts here
      // let creditsVal = 0;

      // var creditsValue = new Text(`10,000,160`, betTextStyle);
      var creditsValue = new Text(`${creditsVal.toLocaleString()}`, betTextStyle);
      creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
      creditsValue.y = (app.screen.height - CreditsBg.height) / 0.97;
      app.stage.addChild(creditsValue);

      var creditsInput = parseFloat(creditsVal / 100);
      let creditsOutput =
        creditsInput % 1 === 0
          ? creditsInput.toFixed(2).replace(/\.0$/, "")
          : creditsInput.toFixed(2);
      //creditsSubValue
      const creditsSubValue = new Text(`$${creditsOutput}`, betSubTextStyle);
      creditsSubValue.x = (app.screen.width - creditsSubValue.width) / 3.19;
      creditsSubValue.y = (app.screen.height - CreditsBg.height) / 0.92;
      app.stage.addChild(creditsSubValue);

      function updateCredits(creditsVal) {
        app.stage.removeChild(creditsValue);
        let creditsValueQ = new Text(`${creditsVal.toLocaleString()}`, betTextStyle);
        creditsValueQ.x =
          (creditsVal == 0
            ? GameFrame.width - CreditText.width / 1.2
            : GameFrame.width - CreditText.width) / 2.01;
        creditsValueQ.y = (app.screen.height - CreditsBg.height) / 0.97;
        app.stage.addChild(creditsValueQ);
        creditsValue = creditsValueQ;


      }
      function updateCreditsSubVal() {
        let input = parseFloat(creditsVal / 100);
        let output =
          input % 1 === 0
            ? input.toFixed(2).replace(/\.0$/, "")
            : input.toFixed(2);
        creditsSubValue.text = `$${output}`;
        creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;

      }
      //credits text added

      // ********* Bet Image imported/added
      const BetBg = new Sprite(Texture.from("/assets/Backgrounds/Hud_Bet.png"));
      BetBg.height = app.screen.height / 7;
      BetBg.width = app.screen.width / 7;
      BetBg.x = (app.screen.width - BetBg.width) / 1.42;
      BetBg.y = (app.screen.height - BetBg.height) / 1;
      app.stage.addChild(BetBg);

      // Bet text added
      const BetText = new Text("BET", betTextStyle);
      BetText.x = (app.screen.width - BetText.width) / 1.46;
      BetText.y = (app.screen.height - BetBg.height) / 1.02;
      app.stage.addChild(BetText);

      // Bet text added
      const BetValue = new Text(`${betVal}`, betTextStyle);
      BetValue.x = (app.screen.width - BetText.width) / 1.455;
      BetValue.y = (app.screen.height - BetBg.height) / 0.97;
      app.stage.addChild(BetValue);

      // Bet text added
      let input = parseFloat(betVal / 100);
      let BetSuboutput =
        input % 1 === 0
          ? input.toFixed(2).replace(/\.0$/, "")
          : input.toFixed(2);
      //BetSubValue
      const BetSubValue = new Text(`$${BetSuboutput}`, betSubTextStyle);
      BetSubValue.x = (app.screen.width - BetText.width) / 1.466;

      // BetSubValue.x = (BetSubValue.lenght==9) (app.screen.width - BetText.width) / 1.465;
      BetSubValue.y = (app.screen.height - BetBg.height) / 0.92;
      app.stage.addChild(BetSubValue);

      const winTextStyle = new TextStyle({
        fontSize: app.screen.width / 45,
        fontWeight: 400,
        fontFamily: "Arial , roboto, sans-serif",
        fill: "#ffff00",
        stroke: { color: "#000", width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });

      var winTextValStyle = new TextStyle({
        fontSize: app.screen.width / 45,
        fontWeight: 400,
        fontFamily: "Arial , roboto, sans-serif",
        fill: "#fff",
        stroke: { color: "#000", width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });

      // Win text added
      const WinText = new Text("WIN", winTextStyle);
      WinText.x = (app.screen.width - WinText.width) / 2;
      WinText.y = (app.screen.height - BetBg.height) / 1.03;
      app.stage.addChild(WinText);

      // Win text value added
      var WinTextvalue = new Text(`${winValue}`, winTextValStyle);
      WinTextvalue.x = winValue === 0 ? (app.screen.width - WinText.width) / 1.95 : (app.screen.width - WinText.width) / 1.96;
      WinTextvalue.y = (app.screen.height - BetBg.height) / 0.97;
      app.stage.addChild(WinTextvalue);

      // let updateWinVal = 0;

      function UpdateWinValue(value) {
        // console.log(winValue, 'winValue=====winValue')
        if (value === 0) {
          showWinValue(value)
        } else {
          for (let i = 1; i <= value; i++) {
            setTimeout(() => {
              // console.log(i);
              showWinValue(i)
            }, i * 5); // Delay increases with each iteration UpdateWinValue
          }
        }
      }

      var subWinTextvalue = new Text(`$${'0.00'}`, betSubTextStyle);
      subWinTextvalue.x = X1percent * 47.5;
      subWinTextvalue.y = (app.screen.height - BetBg.height) / 0.91;
      app.stage.addChild(subWinTextvalue);

      function showWinValue(winVal) {
        app.stage.removeChild(WinTextvalue);
        app.stage.removeChild(subWinTextvalue);
        let WinTextvalueUpdated = new Text(`${winVal}`, winTextValStyle);
        WinTextvalueUpdated.x = (app.screen.width - WinText.width) / 1.95;
        WinTextvalueUpdated.y = (app.screen.height - BetBg.height) / 0.97;
        app.stage.addChild(WinTextvalueUpdated);
        WinTextvalue = WinTextvalueUpdated;
        if (winVal > 0) {
          let subwin = parseFloat(winVal / 100);
          var subwinVal =
            subwin % 1 === 0
              ? subwin.toFixed(2).replace(/\.0$/, "")
              : subwin.toFixed(2);
          let subWinTextvalueupdated = new Text(`$${subwinVal}`, betSubTextStyle);
          subWinTextvalueupdated.x = (app.screen.width - WinText.width) / 1.95;
          subWinTextvalueupdated.y = (app.screen.height - BetBg.height) / 0.91;
          app.stage.addChild(subWinTextvalueupdated);
          subWinTextvalue = subWinTextvalueupdated
        } else {
          let subWinTextvalueupdated = new Text(`$${'0.00'}`, betSubTextStyle);
          subWinTextvalueupdated.x = (app.screen.width - WinText.width) / 1.95;
          subWinTextvalueupdated.y = (app.screen.height - BetBg.height) / 0.91;
          app.stage.addChild(subWinTextvalueupdated);
          subWinTextvalue = subWinTextvalueupdated;
          subWinTextvalue.x = X1percent * 47.5;
        }
      }




      // UpdateWinValue(50)
      // ********* Circular Image/Denomination Image imported
      const DenominationImg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_tutoriales.png")
      );
      DenominationImg.height = app.screen.height / 7;
      DenominationImg.width = app.screen.height / 7;
      DenominationImg.x = (app.screen.width - DenominationImg.width) / 10;
      DenominationImg.y = (app.screen.height - DenominationImg.height) / 1;
      app.stage.addChild(DenominationImg);

      // Circular Image/Denomination text added
      const DenominationText = new Text(`${1}\u20B5`, coinDenomTextStyle);
      DenominationText.x = (app.screen.width - DenominationImg.width) / 8;
      DenominationText.y = (app.screen.height - DenominationText.height) / 1.05;
      app.stage.addChild(DenominationText);

      // ********* Info Image imported
      const InfoImg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_Game1of10.png")
      );
      InfoImg.height = app.screen.height / 16;
      InfoImg.width = app.screen.width / 20;
      InfoImg.x = (app.screen.width - InfoImg.width) / 9;
      InfoImg.y = (app.screen.height - InfoImg.height) / 1.2;
      app.stage.addChild(InfoImg);

      const infoTextStyle = new TextStyle({
        fontSize: app.screen.width / 90,
        fontWeight: 400,
        fill: "#fff",
        fontFamily: "roboto, sans-serif , Arial",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });

      // Info text added
      const InfoText = new Text("INFO", infoTextStyle);
      InfoText.x = (app.screen.width - InfoText.width) / 8.25;
      InfoText.y = (app.screen.height - InfoImg.height) / 1.175;
      app.stage.addChild(InfoText);

      // ********* FoF/Flames of Fortune Image imported
      const FoFImg = new Sprite(Texture.from("/assets/Link/FoF-Logo.png"));
      FoFImg.height = app.screen.height / 10;
      FoFImg.width = app.screen.width / 8;
      FoFImg.x = (app.screen.width - FoFImg.width) / 1.15;
      FoFImg.y = (app.screen.height - FoFImg.height) / 1.02;
      app.stage.addChild(FoFImg);

      // Spin Button added here
      const SpinbuttonBg2 = Texture.from("/assets/Icons/spin.png");
      const SpinBtn2 = new Sprite(SpinbuttonBg2);
      SpinBtn2.alpha = 1;
      SpinBtn2.height = app.screen.height / 11;
      SpinBtn2.width = app.screen.width / 14;
      SpinBtn2.y = (app.screen.height - SpinBtn2.height) / 2.2;
      SpinBtn2.x = Math.round((bottom.width * 4 - SpinBtn2.width * 1.5) / 4.93);
      app.stage.addChild(SpinBtn2);
      SpinBtn2.visible = false;

      // Spin Button added here
      const SpinbuttonBg = Texture.from("/assets/Icons/spin.png");
      const SpinBtn = new Sprite(SpinbuttonBg);
      SpinBtn.alpha = 1;
      SpinBtn.height = app.screen.height / 11;
      SpinBtn.width = app.screen.width / 14;
      SpinBtn.y = (app.screen.height - SpinBtn.height) / 2.2;
      SpinBtn.x = Math.round((bottom.width * 4 - SpinBtn.width * 1.5) / 4.93);
      bottom.addChild(SpinBtn);



      const reelSymbolsContainer = new Container();
      reelSymbolsContainer.height = app.screen.height / 3;
      reelSymbolsContainer.mask = new Graphics();
      reelSymbolsContainer.mask.beginFill(0xffffff);
      reelSymbolsContainer.mask.drawRect(
        0,
        0,
        (app.screen.width - reelSymbolsContainer.width) / 1,
        (app.screen.height - reelSymbolsContainer.height) / 1.25
      ); // set the mask to a rectangle with a width of 400 and a height of 300
      reelSymbolsContainer.mask.endFill();
      reelSymbolsContainer.hitArea = new Rectangle(
        0,
        0,
        (app.screen.width - reelSymbolsContainer.width) / 2,
        (app.screen.height - reelSymbolsContainer.height) / 1.35
      );


      const winBlackBg = new Sprite(Texture.from('/assets/MainBG/Dorado_background.png'));
      winBlackBg.height = app.screen.height;
      winBlackBg.width = app.screen.width;
      app.stage.addChildAt(winBlackBg, 29)
      winBlackBg.visible = false;
      winBlackBg.x = 0;
      winBlackBg.y = 0;

      app.stage.addChildAt(reelSymbolsContainer, 20);

      // Build the reels
      const reels = [];
      const reelContainer = new Container();
      let reelDataIndex = 0;
      for (let i = 0; i < 5; i++) {
        const rc = new Container();

        rc.x = i * REEL_WIDTH;
        reelContainer.addChild(rc);

        const reel = {
          container: rc,
          symbols: [],
          position: 0,
          previousPosition: 0,
          visible: "true",
          blur: new BlurFilter()
        };
        // Build the symbols
        for (let j = 0; j < 3; j++) {
          // console.log("slotTextures", slotTextures);
          let data = (slotTextures?.length > 0 && reelData?.length > 0) ? slotTextures?.filter(
            (r) => r?.name == reelData[reelDataIndex]
          )[0] : [];

          // console.log(data, "data");
          const symbol = new Sprite(data);
          symbol.y = j * SYMBOL_SIZE;
          symbol.scale.x = symbol.scale.y = Math.min(
            SYMBOL_SIZE / symbol.width,
            SYMBOL_SIZE / symbol.height
          );
          symbol.x = Math.round((SYMBOL_SIZE - symbol.width) / 2);
          reel.symbols.push(symbol);
          rc.addChild(symbol);
          if (reelData?.length == reelDataIndex) {
            reelDataIndex = 0;
          } else {
            reelDataIndex++;
          }
        }

        reels.push(reel);
      }

      // console.log(reels[0], "reels[0]");

      //  reelContainer.addChild(reels)
      reelSymbolsContainer.addChild(reelContainer);

      // Build top & bottom covers and position reelContainer
      reelContainer.height = app.screen.height / 2;
      reelContainer.y = (app.screen.height - reelContainer.height) / 1.36;
      reelContainer.x = (app.screen.width - reelContainer.width) / 2;

      // Create gradient fill
      const fill = new FillGradient(0, 0, 0, 36 * 1.7);

      const colors = [0xffffff, 0x00ff99].map((color) =>
        Color.shared.setValue(color).toNumber()
      );

      colors.forEach((number, index) => {
        const ratio = index / colors.length;

        fill.addColorStop(ratio, number);
      });
      app.stage.addChild(top);
      app.stage.addChild(bottom);
      // Set the interactivity.
      bottom.eventMode = "static";
      bottom.cursor = "pointer";
      bottom.addListener("click", async () => {
        const modal = document.querySelectorAll("addCredit");

        if (creditsVal >= (BetSuboutput * coinDenonimation) * 100) {
          modal.visible = false;
          payLineTrueHandle()
          console.log(creditsVal, betVal, (BetSuboutput * coinDenonimation) * 100)
          startPlay()
          await gameSpinApi(false);

        } else {
          modalContainer.visible = true;
          console.log(creditsVal, betVal)
          setTimeout(() => { modalContainer.visible = false; }, 1500)
        }
      });

      const creditPopup = new TextStyle({
        fontSize: app.screen.width / 60,
        // fontStyle: 'italic',
        align: 'center', // set text alignment to center
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "normal",
        fill: "#fff",
        // stroke: { color: 0x4a1850, width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440,
        lineHeight: 60, // set line height
      });

      const modalContainer = new Container();
      // const modalBackground = new Graphics();
      const modalBackground = new Sprite(Texture.from('/assets/MainBG/modalBg.png'));
      modalBackground.scale.set(0.6);
      modalContainer.addChild(modalBackground);
      modalContainer.addChild(modalBackground);
      const modalContent = new Text(`Not enough Credits! Please,${window.innerWidth > 1200 && window.innerWidth < 1400 ? '\n' : ''}  Add credits to play.`, creditPopup);
      modalContent.anchor.x = window.innerWidth > 1600 ? -0.3 : (window.innerWidth > 1366 && window.innerWidth < 1600) ? -0.9 / 5 : -0.5;
      modalContent.anchor.y = -0.8;
      modalContainer.addChild(modalContent);
      modalBackground.width = app.screen.width / 3;
      modalBackground.height = app.screen.height / 3;
      modalContainer.x = app.screen.width / 2;
      modalContainer.y = app.screen.height / 2;
      modalContainer.x = (app.screen.width - modalContainer.width) / 2
      modalContainer.y = (app.screen.height - modalContainer.height) / 2

      modalBackground.x = window.innerWidth > 1600 ? (app.screen.width - modalBackground.width) / 90 : (window.innerWidth > 1366 && window.innerWidth < 1600) ? (app.screen.width - modalBackground.width) / 80 : (app.screen.width - modalBackground.width) / 15;
      modalBackground.y = (app.screen.height - modalBackground.height) / 15;
      app.stage.addChildAt(modalContainer, 30)

      modalContainer.visible = false;


      const spinAudio = new Audio('/assets/Audios/railsStartSpinning.wav');
      spinAudio.volume = 0.5;

      const stopReel = new Audio('/assets/Audios/railStop.wav');
      stopReel.volume = 0.5;

      const stopReel1 = new Audio('/assets/Audios/railStop.wav');
      stopReel.volume = 0.5;

      const stopReel2 = new Audio('/assets/Audios/railStop.wav');
      stopReel.volume = 0.5;

      const stopReel3 = new Audio('/assets/Audios/railStop.wav');
      stopReel.volume = 0.5;

      const stopReel4 = new Audio('/assets/Audios/railStop.wav');
      stopReel.volume = 0.5;


      let running = false;

      let reelArr = [];
      function startPlay() {
        subWinTextvalue.x = X1percent * 47.5;
        payLineTrueHandle()
        winBlackBg.visible = false;
        //bottom.eventMode = "none";
        //bottom.cursor = "none";
        bottom.visible = false;
        SpinBtn2.visible = true;
        if (running) return;
        running = true;
        removingfunction()
        reelSymbolsContainer.addChild(reelContainer);
        for (let i = 0; i < reels.length; i++) {
          const r = reels[i];
          var target = 0;
          target = r.position + 30;
          const time = 1000 + i * 300;

          tweenTo(
            r,
            "position",
            target,
            time,
            backout(0.5),
            null,
            i === reels.length - 1 ? reelsComplete : null
          );
          if (reelArr.lenght < 5) {
            reelArr.splice(0, 5);
          }
          reelArr.push(r);
          target = r.position + 30;
          // console.log(reelArr,' s.texture s.texture')
        }

        // creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 :creditsValue.text.length === 5 ? X1percent * 30.1 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3: creditsValue.text.length === 11 ? X1percent * 27.5 : X1percent * 31.5;
        // creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 4 ? X1percent * 30 :creditsValue.text.length === 5 ? X1percent * 29.5 : creditsValue.text.length === 6 ? X1percent * 29 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 28.5 : creditsValue.text.length === 9 ? X1percent * 27.7: creditsValue.text.length === 10 ? X1percent * 27 : X1percent * 31.5;
      }

      // Reels done handler.
      function reelsComplete() {
        running = false;
        bottom.visible = true;
        SpinBtn2.visible = false;
        // functionSpinningWheel()
      }

      // Listen for animate update.

      var dot = 0;
      var hitflag = false;

      app.ticker.add(() => {
        // Update the slots.
        for (let i = 0; i < reels.length; i++) {
          const r = reels[i];
          // Update blur filter y amount based on speed.
          // This would be better if calculated with time in mind also. Now blur depends on frame rate.
          r.blur.blurY = (r.position - r.previousPosition) * 8;
          r.previousPosition = r.position;

          // Update symbol positions on reel.
          for (let j = 0; j < r.symbols.length; j++) {
            const s = r.symbols[j];
            const prevy = s.y;
            if (running) {
              dot += 1;
              payLineTrueHandle()
              // console.log(dot, "dot=========================================");
              if (dot === 40) {
                dot = 0;
                hitflag = false;
                return setsymbols();
              }
            }
            s.y =
              ((r.position + j) % r.symbols.length) * 1.25 * SYMBOL_SIZE -
              SYMBOL_SIZE;
            if (s.y < 0 && prevy > SYMBOL_SIZE) {
              s.texture =
                slotTextures[Math.floor(Math.random() * slotTextures.length)];
              s.scale.x = s.scale.y = Math.min(
                SYMBOL_SIZE / s.texture.width,
                SYMBOL_SIZE / s.texture.height
              );
              s.x = Math.round((SYMBOL_SIZE - s.width) / 2);
            }
          }
        }
      });

      var fireCount = 0;
      function setsymbols() {
        fireCount += 1;
        if (hitflag === false) {

          setTimeout(() => {
            reels[0].container.children[0].texture = imgArrSlot[0];
            reels[0].container.children[1].texture = imgArrSlot[1];
            reels[0].container.children[2].texture = imgArrSlot[2];
            // stopReel.play();
          }, 500);
          setTimeout(() => {
            reels[1].container.children[0].texture = imgArrSlot[3];
            reels[1].container.children[1].texture = imgArrSlot[4];
            reels[1].container.children[2].texture = imgArrSlot[5];
          }, 900);
          setTimeout(() => {
            reels[2].container.children[0].texture = imgArrSlot[6];
            reels[2].container.children[1].texture = imgArrSlot[7];
            reels[2].container.children[2].texture = imgArrSlot[8];
            // stopReel1.play();
          }, 1200);
          setTimeout(() => {
            reels[3].container.children[0].texture = imgArrSlot[9];
            reels[3].container.children[1].texture = imgArrSlot[10];
            reels[3].container.children[2].texture = imgArrSlot[11];
            // stopReel2.play();
          }, 1500);
          setTimeout(() => {
            reels[4].container.children[0].texture = imgArrSlot[12];
            reels[4].container.children[1].texture = imgArrSlot[13];
            reels[4].container.children[2].texture = imgArrSlot[14];
            // stopReel3.play();
          }, 1800);
        }
        if (fireCount === 19) {
          // console.log(fireCount, "fireCount-----------------------fireCount");
          spinReelAnimation();
          fireCount = 0;
        }
      }

      function spinReelAnimation() {
        setTimeout(() => {
          let ttArr = [];
          let allName = [];

          for (let i = 0; i < 5; i++) {
            for (let j = 0; j < 3; j++) {
              allName.push(reels[i].container.children[j].texture.name);

              if (reels[i].container.children[j].texture.name === "TT") {
                ttArr.push({
                  i,
                  j,
                  name: reels[i].container.children[j].texture.name
                });
              }
            }
          }
          console.log(allName, "allName----------------allName");
          console.log(ttArr, "fireCount-----------------------fireCount");

          ttArr.forEach((el) => {
            if (el.i === 0 && el.name === "TT") {
              console.log(0, "fireCount-----------------------fireCount", el.j);
              reels[0].container.children[el.j].visible = false;
            } else if (el.i === 1 && el.name === "TT") {
              console.log(1, "fireCount-----------------------fireCount", el.j);
              reels[1].container.children[el.j].visible = false;
            } else if (el.i === 2 && el.name === "TT") {
              console.log(2, "fireCount-----------------------fireCount", el.j);
              reels[2].container.children[el.j].visible = false;
            } else if (el.i === 3 && el.name === "TT") {
              console.log(3, "fireCount-----------------------fireCount", el.j);
              reels[3].container.children[el.j].visible = false;
            } else if (el.i === 4 && el.name === "TT") {
              console.log(4, "fireCount-----------------------fireCount", el.j);
              reels[4].container.children[el.j].visible = false;
            }
          });
          if (
            reels[0].container.children[0].visible === false &&
            reels[0].container.children[0].texture.name === "TT"
          ) {
            spriteA00.y = app.screen.height / 3.5;
            spriteA00.x = app.screen.width / 4;
            animateSpin();
          }
          if (
            reels[0].container.children[1].visible === false &&
            reels[0].container.children[1].texture.name === "TT"
          ) {
            spriteA01.y = app.screen.height / 2.05;
            spriteA01.x = app.screen.width / 4;
            animateSpin1();
          }
          if (
            reels[0].container.children[2].visible === false &&
            reels[0].container.children[2].texture.name === "TT"
          ) {
            spriteA02.y = app.screen.height / 1.435;
            spriteA02.x = app.screen.width / 4;
            animateSpin2();
          }

          //00000000
          if (
            reels[1].container.children[0].visible === false &&
            reels[1].container.children[0].texture.name === "TT"
          ) {
            spriteA10.y = app.screen.height / 3.5;
            spriteA10.x = app.screen.width / 2.67;
            animateSpin3();
          }
          if (
            reels[1].container.children[1].visible === false &&
            reels[1].container.children[1].texture.name === "TT"
          ) {
            spriteA11.y = app.screen.height / 2.05;
            spriteA11.x = app.screen.width / 2.67;
            animateSpin4();
          }
          if (
            reels[1].container.children[2].visible === false &&
            reels[1].container.children[2].texture.name === "TT"
          ) {
            spriteA12.y = app.screen.height / 1.435;
            spriteA12.x = app.screen.width / 2.67;
            animateSpin5();
          }

          //  1111111
          if (
            reels[2].container.children[0].visible === false &&
            reels[2].container.children[0].texture.name === "TT"
          ) {
            spriteA20.y = app.screen.height / 3.5;
            spriteA20.x = app.screen.width / 2;
            animateSpin6();
          }
          if (
            reels[2].container.children[1].visible === false &&
            reels[2].container.children[1].texture.name === "TT"
          ) {
            spriteA21.y = app.screen.height / 2.05;
            spriteA21.x = app.screen.width / 2;
            animateSpin7();
          }
          if (
            reels[2].container.children[2].visible === false &&
            reels[2].container.children[2].texture.name === "TT"
          ) {
            spriteA22.y = app.screen.height / 1.435;
            spriteA22.x = app.screen.width / 2;
            animateSpin8();
          }

          //  22222222
          if (
            reels[3].container.children[0].visible === false &&
            reels[3].container.children[0].texture.name === "TT"
          ) {
            spriteA30.y = app.screen.height / 3.5;
            spriteA30.x = app.screen.width / 1.6;
            animateSpin9();
          }
          if (
            reels[3].container.children[1].visible === false &&
            reels[3].container.children[1].texture.name === "TT"
          ) {
            spriteA31.y = app.screen.height / 2.05;
            spriteA31.x = app.screen.width / 1.6;
            animateSpin10();
          }
          if (
            reels[3].container.children[2].visible === false &&
            reels[3].container.children[2].texture.name === "TT"
          ) {
            spriteA32.y = app.screen.height / 1.435;
            spriteA32.x = app.screen.width / 1.6;
            animateSpin11();
          }
          //  333333333333333
          if (
            reels[4].container.children[0].visible === false &&
            reels[4].container.children[0].texture.name === "TT"
          ) {
            spriteA40.y = app.screen.height / 3.5;
            spriteA40.x = app.screen.width / 1.335;
            animateSpin12();
          }
          if (
            reels[4].container.children[1].visible === false &&
            reels[4].container.children[1].texture.name === "TT"
          ) {
            spriteA41.y = app.screen.height / 2.05;
            spriteA41.x = app.screen.width / 1.335;
            animateSpin13();
          }
          if (
            reels[4].container.children[2].visible === false &&
            reels[4].container.children[2].texture.name === "TT"
          ) {
            spriteA42.y = app.screen.height / 1.435;
            spriteA42.x = app.screen.width / 1.335;
            animateSpin14();
          }
        }, 1800);
      }

      // Very simple tweening utility function. This should be replaced with a proper tweening library in a real product.
      const tweening = [];

      function tweenTo(
        object,
        property,
        target,
        time,
        easing,
        onchange,
        oncomplete
      ) {
        const tween = {
          object,
          property,
          propertyBeginValue: object[property],
          target,
          easing,
          time,
          change: onchange,
          complete: oncomplete,
          start: Date.now()
        };

        tweening.push(tween);
        return tween;
      }
      // Listen for animate update.

      app.ticker.add(() => {
        const now = Date.now();
        const remove = [];

        for (let i = 0; i < tweening.length; i++) {
          const t = tweening[i];
          const phase = Math.min(1, (now - t.start) / t.time);
          t.object[t.property] = lerp(
            t.propertyBeginValue,
            t.target,
            t.easing(phase)
          );
          if (t.change) t.change(t);
          if (phase === 1) {
            t.object[t.property] = t.target;
            if (t.complete) t.complete(t);
            remove.push(t);
            console.log(reels[0], "firstReelRowfirstReelRow");
          }
        }
        for (let i = 0; i < remove.length; i++) {
          tweening.splice(tweening.indexOf(remove[i]), 1);
        }
      });


      // Basic lerp funtion.
      function lerp(a1, a2, t) {
        return a1 * (1 - t) + a2 * t;
      }

      // Backout function from tweenjs.
      function backout(amount) {
        return (t) => +t * ((amount + 1) * t + amount) + 1;
      }



      const lightImgBG = new Sprite(
        Texture.from("/assets/Slots/Slot_Sprite/Calavera.png")
      );


      const paylineBG = new Sprite(
        Texture.from("/assets/Tutorials/popUpBackground.png")
      );
      paylineBG.height = app.screen.height / 1.3 - 100;
      paylineBG.width = app.screen.width / 1.5 - 50;
      paylineBG.x = (app.screen.width - paylineBG.width) / 2;
      paylineBG.y = (app.screen.height - paylineBG.height) / 2;
      app.stage.addChild(paylineBG);

      const imagesPerRow = 10; // Number of images per row
      const imgSize = 50; // Size of each image
      const startX = paylineBG.x - 100; // Starting X position
      const rowY = paylineBG.y + 280; // Starting Y position for the first row


      const textOptions = {
        fontFamily: 'fantasy',
        fontSize: 80,
        fontWeight: '300',
        fill: 0xdddddd, // White color
        align: 'center',
        stroke: { color: "#000", width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
      };



      const gameRulesBG = new Sprite(
        Texture.from("/assets/Tutorials/popUpBackground.png")
      );


      const gameRules = [
        "Select credits per line to start betting.",
        "${} Substitute for all symbols except scatter symbols ${} & Symbols.",
        "A player can't get ${} on the first reel on base game.",
        "Can only appear on the second, third, fourth and fifth reel.",
      ]

      gameRulesBG.height = app.screen.height / 1.3 - 100;
      gameRulesBG.width = app.screen.width / 1.5 - 50;
      gameRulesBG.x = (app.screen.width - gameRulesBG.width) / 2;
      gameRulesBG.y = (app.screen.height - gameRulesBG.height) / 2;
      app.stage.addChild(gameRulesBG);



      const gameRuleStyle = {
        fontFamily: 'fantasy',
        fontSize: 60,
        fontWeight: '100',
        fill: 0xdddddd, // White color
        align: 'center',
        stroke: { color: "#000", width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
      };

      const gameRulesText = new Text('Rules', gameRuleStyle);
      gameRulesText.x = (gameRulesBG.width - gameRulesText.width) / 1.15; // X position
      gameRulesText.y = gameRulesBG.height / 2.5; // Y position
      gameRulesText.anchor.set(0.5); // Center the text
      gameRulesBG.addChild(gameRulesText)


      const gameRuleContainer = new Container();
      gameRuleContainer.x = app.screen.height / 8;
      gameRuleContainer.y = app.screen.height / 3;
      gameRulesBG.addChild(gameRuleContainer);


      gameRules.forEach((paragraph, index) => {
        // Create text style
        const style = new TextStyle({
          fontFamily: 'fantasy',
          fontSize: 24,
          fontWeight: '100',
          fill: 0xdddddd, // White color
          align: 'center',
          letterSpacing: 3,
          stroke: { color: "#000", width: 3 },
          dropShadow: {
            color: "#494949",
            // angle: Math.PI / 40,
            blur: 1,
            distance: 2
          },
        });

        // Create text object for each paragraph
        const text = new Text(paragraph.toUpperCase(), style);
        text.anchor.set(0.5, 0);
        text.y = app.screen.width / 1;
        // Position the text object
        text.x = (gameRulesBG.width - text.text.length) / 1.4; // X position
        text.y = index * 34; // Y position, spaced out by 60 pixels

        // Add the text object to the container
        gameRuleContainer.addChild(text);
      });


      const rules2 = [
        "On paid games, all wins begin from the left most reel and pay left to right on adjacent reels,",
        "expect for scatter symbols ${}",
        "Scatter symbols ${} ca't from multiple lines or prizes.",
        "Scatter symbols ${} can only appear once per reel.",
        "Scatter symbols ${} wins are added to the other win lines",
        "All wins show only in highlighted lines, except scatter symbols ${} & symbols ${}",
      ]

      const gameRuleContainer2 = new Container();
      gameRuleContainer2.x = app.screen.height / 8;
      gameRuleContainer2.y = app.screen.height / 2;
      gameRulesBG.addChild(gameRuleContainer2);


      rules2.forEach((paragraph, index) => {
        // Create text style
        const style = new TextStyle({
          fontFamily: 'fantasy',
          fontSize: 24,
          fontWeight: '100',
          fill: 0xdddddd, // White color
          align: 'center',
          letterSpacing: 3,
          stroke: { color: "#000", width: 3 },
          dropShadow: {
            color: "#494949",
            // angle: Math.PI / 40,
            blur: 1,
            distance: 2
          },
        });

        // Create text object for each paragraph
        const text = new Text(paragraph.toUpperCase(), style);
        text.anchor.set(0.5, 0);
        // Position the text object
        text.x = (gameRulesBG.width - text.text.length) / 1.4; // X position
        text.y = index * 34; // Y position, spaced out by 60 pixels

        // Add the text object to the container
        gameRuleContainer2.addChild(text);
      });

      const freeGameBouns = new Sprite(
        Texture.from("/assets/Tutorials/popUpBackground.png")
      );


      const rules1 = [
        "5 Free Games are awareded by 3 or more scatter symbols.",
        `5 ${lightImgBG} Pays bet x 100.`,
        `4 ${lightImgBG} Pays bet x 10.`,
        `3 ${lightImgBG} Pays bet x 2.`,
        "During the free games bonus, all of the symbols in the first & last reels will become wilds.",
        "During the free games bonus, 3 scatter symbols ${} will award 5 additional free games.",
      ]

      freeGameBouns.height = app.screen.height / 1.3 - 100;
      freeGameBouns.width = app.screen.width / 1.5 - 50;
      freeGameBouns.x = (app.screen.width - freeGameBouns.width) / 2;
      freeGameBouns.y = (app.screen.height - freeGameBouns.height) / 2;
      app.stage.addChild(freeGameBouns);



      const freeGameBounsText = {
        fontFamily: 'fantasy',
        fontSize: 40,
        fontWeight: '100',
        fill: '#dda40e', // White color
        align: 'center',
        stroke: { color: "#622e0c", width: 5 },
        dropShadow: {
          color: '#71420a',
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
      };

      const gameBounsText = new Text('Free games bonus'.toUpperCase(), freeGameBounsText);
      gameBounsText.x = (freeGameBouns.width - gameBounsText.width) / .95; // X position
      gameBounsText.y = freeGameBouns.height / 2.5; // Y position
      gameBounsText.anchor.set(0.5); // Center the text
      freeGameBouns.addChild(gameBounsText)


      const firstContainer = new Container();
      firstContainer.y = app.screen.height / 3.3;
      firstContainer.x = app.screen.height / 8;
      freeGameBouns.addChild(firstContainer);


      rules1.forEach((paragraph, index) => {
        // Create text style
        const style = new TextStyle({
          fontFamily: 'fantasy',
          fontSize: 24,
          fontWeight: '100',
          fill: 0xdddddd, // White color
          align: 'center',
          letterSpacing: 3,
          stroke: { color: "#000", width: 3 },
          dropShadow: {
            color: "#494949",
            // angle: Math.PI / 40,
            blur: 1,
            distance: 2
          },
        });

        // Create text object for each paragraph
        const text = new Text(paragraph.toUpperCase(), style);
        text.anchor.set(0.5, 0);
        // Position the text object
        text.x = (freeGameBouns.width - text.text.length) / 1.4; // X position
        text.y = index * 34; // Y position, spaced out by 60 pixels

        // Add the text object to the container
        firstContainer.addChild(text);
      });

      freeGameBouns.visible = false
      paylineBG.visible = false
      // payOutBG.visible = false
      gameRulesBG.visible = false



      const ONoff = new Sprite(
        Texture.from("/assets/Tutorials/Return-Button.png")
      );
      ONoff.height = app.screen.height / 5;
      ONoff.width = app.screen.width / 9;
      ONoff.x = app.screen.width - 1100;
      ONoff.y = (app.screen.height / 2) + 240;

      const prev = new Sprite(
        Texture.from("/assets/Tutorials/Boton-Flecha.png")
      );
      prev.x = app.screen.width - 1280;
      prev.y = (app.screen.height / 2) + 270;
      // prev.y = app.screen.height + 380;  
      prev.height = app.screen.height / 7;
      prev.width = app.screen.height / 7;

      const Next = new Sprite(
        Texture.from("/assets/Tutorials/Boton-Flecha.png")
      );
      Next.x = app.screen.width - 700;
      Next.y = (app.screen.height / 2) + 400;
      // Next.y = app.screen.height + 380;  
      Next.height = app.screen.height / 7;
      Next.width = app.screen.height / 7;
      Next.rotation = Math.PI;
      app.stage.addChild(prev);
      app.stage.addChild(Next);
      app.stage.addChild(ONoff);

      prev.visible = false;
      Next.visible = false;
      ONoff.visible = false;



      InfoImg.eventMode = "static";
      InfoImg.cursor = "pointer";
      InfoImg.addListener("click", () => {
        setPayoutTable(!isPayoutTable)
      });


      //doller btn container
      // Add play text
      const style = new TextStyle({
        fontFamily: "Arial",
        fontSize: 56,
        fontStyle: "italic",
        fontWeight: "bold",
        fill: { fill },
        stroke: { color: 0x4a1850, width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });

      const style1 = new TextStyle({
        fontFamily: "Arial",
        fontSize: 250,
        fontStyle: "italic",
        fontWeight: "bold",
        fill: 0xffffff,
        stroke: { color: "#008000", width: 5 },
        dropShadow: {
          color: "#008000",
          angle: Math.PI / 6,
          blur: 14,
          distance: 6
        }
      });
      const dollarBg = new Sprite(
        Texture.from(`/assets/Denominations/Denom-Slide.png`)
      );

      const dollarbGText = new Text(`CHOOSE YOUR DENOMINATION`, style1);

      dollarbGText.x = 800;
      dollarbGText.y = 200;

      const dollarText1 = new Text(`${1}\u20B5`, style);
      const dollarText2 = new Text(`${2}\u20B5`, style);
      const dollarText3 = new Text(`${5}\u20B5`, style);
      const dollarText4 = new Text(`${10}\u20B5`, style);
      const dollarText5 = new Text(`${25}\u20B5`, style);

      const dollarBtn1 = new Sprite(
        Texture.from("/assets/Denominations/Denom-Button-1.png")
      );
      dollarBtn1.addChild(dollarText1);
      dollarText1.width = 400;
      dollarText1.height = 300;
      dollarText1.x = 240;
      dollarText1.y = 155;

      const dollarBtn2 = new Sprite(
        Texture.from("/assets/Denominations/Denom-Button-2.png")
      );
      dollarBtn2.addChild(dollarText2);
      dollarText2.width = 400;
      dollarText2.height = 300;
      dollarText2.x = 240;
      dollarText2.y = 155;

      const dollarBtn3 = new Sprite(
        Texture.from("/assets/Denominations/Denom-Button-3.png")
      );
      dollarBtn3.addChild(dollarText3);
      dollarText3.width = 400;
      dollarText3.height = 300;
      dollarText3.x = 240;
      dollarText3.y = 155;

      const dollarBtn4 = new Sprite(
        Texture.from("/assets/Denominations/Denom-Button-4.png")
      );
      dollarBtn4.addChild(dollarText4);
      dollarText4.width = 400;
      dollarText4.height = 300;
      dollarText4.x = 240;
      dollarText4.y = 155;

      const dollarBtn5 = new Sprite(
        Texture.from("/assets/Denominations/Denom-Button-5.png")
      );
      dollarBtn5.addChild(dollarText5);
      dollarText5.width = 400;
      dollarText5.height = 300;
      dollarText5.x = 240;
      dollarText5.y = 155;

      dollarBtn1.width = 900;
      dollarBtn1.height = 520;
      dollarBtn1.x = dollarBg.width / 10;
      dollarBtn1.y = dollarBg.height / 2;
      dollarBtn1.eventMode = "static";
      dollarBtn1.cursor = "pointer";
      dollarBtn1.addEventListener("click", () => {
        dollarBg.visible = false;
        coinDenonimation = 1;
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        // updateCredits(creditsVal/coinDenonimation);
        updateCredits(Math.floor(creditsVal / coinDenonimation));
        DenominationText.text = `${1}\u20B5`;
        DenominationText.x = (app.screen.width - DenominationImg.width) / 8;
        removingfunction()
        payLineTrueHandle()
        responsiveTextMiniMinor()
        winBlackBg.visible = false;
        creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
      });

      dollarBtn2.width = 900;
      dollarBtn2.height = 520;
      dollarBtn2.x = dollarBg.width / 3.8;
      dollarBtn2.y = dollarBg.height / 2;
      dollarBtn2.eventMode = "static";
      dollarBtn2.cursor = "pointer";
      dollarBtn2.addEventListener("click", () => {
        dollarBg.visible = false;
        coinDenonimation = 2;
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        DenominationText.text = `${2}\u20B5`;
        // updateCredits(creditsVal/coinDenonimation);
        updateCredits(Math.floor(creditsVal / coinDenonimation));
        DenominationText.x = (app.screen.width - DenominationImg.width) / 8;
        removingfunction()
        payLineTrueHandle()

        responsiveTextMiniMinor()
        winBlackBg.visible = false;
        creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
      });

      dollarBtn3.width = 900;
      dollarBtn3.height = 520;
      dollarBtn3.x = dollarBg.width / 2.35;
      dollarBtn3.y = dollarBg.height / 2;
      dollarBtn3.eventMode = "static";
      dollarBtn3.cursor = "pointer";
      dollarBtn3.addEventListener("click", () => {
        dollarBg.visible = false;
        coinDenonimation = 5;
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        DenominationText.text = `${5}\u20B5`;
        // updateCredits(creditsVal/coinDenonimation);
        updateCredits(Math.floor(creditsVal / coinDenonimation));
        DenominationText.x = (app.screen.width - DenominationImg.width) / 8;
        removingfunction()
        payLineTrueHandle()

        responsiveTextMiniMinor()
        winBlackBg.visible = false;
        creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
      });

      dollarBtn4.width = 900;
      dollarBtn4.height = 520;
      dollarBtn4.x = dollarBg.width / 1.7;
      dollarBtn4.y = dollarBg.height / 2;
      dollarBtn4.eventMode = "static";
      dollarBtn4.cursor = "pointer";
      dollarBtn4.addEventListener("click", () => {
        dollarBg.visible = false;
        coinDenonimation = 10;
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        DenominationText.text = `${10}\u20B5`;
        // updateCredits(creditsVal/coinDenonimation);
        updateCredits(Math.floor(creditsVal / coinDenonimation));
        DenominationText.x = (app.screen.width - DenominationImg.width) / 8.3;
        removingfunction()
        responsiveTextMiniMinor()
        payLineTrueHandle()

        winBlackBg.visible = false;
        creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
      });

      dollarBtn5.width = 900;
      dollarBtn5.height = 520;
      dollarBtn5.x = dollarBg.width / 1.33;
      dollarBtn5.y = dollarBg.height / 2;
      dollarBtn5.eventMode = "static";
      dollarBtn5.cursor = "pointer";
      dollarBtn5.addEventListener("click", () => {
        dollarBg.visible = false;
        coinDenonimation = 25;
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        DenominationText.text = `${25}\u20B5`;
        // updateCredits(creditsVal/coinDenonimation);
        updateCredits(Math.floor(creditsVal / coinDenonimation));
        DenominationText.x = (app.screen.width - DenominationImg.width) / 8.8;
        removingfunction()
        responsiveTextMiniMinor()
        payLineTrueHandle()

        winBlackBg.visible = false;
        BetSubValue.x = (app.screen.width - BetSubValue.width) / 1.467;
        creditsValue.x = creditsValue.text.length === 3 ? X1percent * 30.8 : creditsValue.text.length === 12 ? X1percent * 27 : creditsValue.text.length === 5 ? X1percent * 30 : creditsValue.text.length === 6 ? X1percent * 29.5 : creditsValue.text.length === 7 ? X1percent * 28.8 : creditsValue.text.length === 8 ? X1percent * 29 : creditsValue.text.length === 9 ? X1percent * 28.3 : creditsValue.text.length === 11 ? X1percent * 27.5 : creditsValue.text.length === 10 ? X1percent * 27.5 : creditsValue.text.length === 2 ? X1percent * 31.5 : X1percent * 32;
      });


      function responsiveTextMiniMinor() {

        console.log(MinorText.text.length, "coinDenonimation", DenominationText.text.length)
        //minor text responsive text
        if (MinorText.text.length >= 9) {
          window.innerWidth > 1440 ? MinorText.x = X1percent * 14.3 : (window.innerWidth > 1367 && window.innerWidth < 1600) ? MinorText.x = X1percent * 15 : MinorText.x = X1percent * 16;
        } else if (MinorText.text.length >= 8) {
          window.innerWidth > 1440 ? MinorText.x = X1percent * 14.5 : MinorText.x = X1percent * 15.5;

        } else if (MinorText.text.length >= 7) {
          window.innerWidth > 1440 ? MinorText.x = X1percent * 15 : MinorText.x = X1percent * 16;

        } else if (MinorText.text.length >= 6) {
          window.innerWidth > 1440 ? MinorText.x = X1percent * 15.4 : MinorText.x = X1percent * 16;

        } else if (MinorText.text.length == 5) {
          window.innerWidth > 1440 ? MinorText.x = X1percent * 15 : MinorText.x = X1percent * 15.5;

        }
        else if (MinorText.text.length <= 4) {
          window.innerWidth > 1440 ? MinorText.x = X1percent * 15 : MinorText.x = X1percent * 16;
        }
        //mini text responsive text
        if (MiniText.text.length >= 8) {
          window.innerWidth > 1440 ? MiniText.x = X1percent * 78 : MiniText.x = X1percent * 78.7;

        }
        else if (MiniText.text.length >= 7) {
          window.innerWidth > 1440 ? MiniText.x = X1percent * 78.5 : MiniText.x = X1percent * 79;
        }
        else if (MiniText.text.length >= 6) {
          window.innerWidth > 1440 ? MiniText.x = X1percent * 79 : MiniText.x = X1percent * 79.5;

        } else if (MiniText.text.length == 5) {
          window.innerWidth > 1440 ? MiniText.x = X1percent * 78 : MiniText.x = X1percent * 78.5;
        }
        else if (MiniText.text.length == 4) {
          window.innerWidth > 1440 ? MiniText.x = X1percent * 79 : MiniText.x = X1percent * 78.6;
        }
        else if (MiniText.text.length <= 3) {
          window.innerWidth > 1440 ? MiniText.x = X1percent * 79 : MiniText.x = X1percent * 79.2;
        }

        //denomination responsive text
        if (DenominationText.text.length >= 3) {
          window.innerWidth > 1440 ? DenominationText.x = X1percent * 11.2 : DenominationText.x = X1percent * 11;
        }
        else {
          window.innerWidth > 1440 ? DenominationText.x = X1percent * 11.8 : DenominationText.x = X1percent * 11.5;
        }




      }


      function removingfunction() {
        setTimeout(() => {
          for (let i = 0; i < removePy.length; i++) {
            app.stage.removeChild(removePy[i]);
          }
        }, 0)
        clearInterval(intervalPayline);


        removePayLines();
        startCount = 0;
        clearInterval(payLineIntervel);
        popupContainer.visible = false;
        UpdateWinValue(0)
        removeAllSprites();
      }


      function payLineTrueHandle() {

        reels[0].container.children[0].visible = true;
        reels[0].container.children[1].visible = true;
        reels[0].container.children[2].visible = true;
        reels[1].container.children[0].visible = true;
        reels[1].container.children[1].visible = true;
        reels[1].container.children[2].visible = true;
        reels[2].container.children[0].visible = true;
        reels[2].container.children[1].visible = true;
        reels[2].container.children[2].visible = true;
        reels[3].container.children[0].visible = true;
        reels[3].container.children[1].visible = true;
        reels[3].container.children[2].visible = true;
        reels[4].container.children[0].visible = true;
        reels[4].container.children[1].visible = true;
        reels[4].container.children[2].visible = true;

        app.stage.removeChild(spriteA00);
        app.stage.removeChild(spriteA01);
        app.stage.removeChild(spriteA02);
        app.stage.removeChild(spriteA10);
        app.stage.removeChild(spriteA11);
        app.stage.removeChild(spriteA12);
        app.stage.removeChild(spriteA20);
        app.stage.removeChild(spriteA21);
        app.stage.removeChild(spriteA22);
        app.stage.removeChild(spriteA30);
        app.stage.removeChild(spriteA31);
        app.stage.removeChild(spriteA32);
        app.stage.removeChild(spriteA40);
        app.stage.removeChild(spriteA41);
        app.stage.removeChild(spriteA42);
      }



      dollarBg.height = app.screen.height / 2.5;
      dollarBg.width = app.screen.width / 2;
      dollarBg.x = app.screen.height / 2;
      dollarBg.y = app.screen.width / 7;
      dollarBg.addChild(dollarbGText);
      dollarBg.addChild(dollarBtn1);
      dollarBg.addChild(dollarBtn2);
      dollarBg.addChild(dollarBtn3);
      dollarBg.addChild(dollarBtn4);
      dollarBg.addChild(dollarBtn5);
      app.stage.addChild(dollarBg);
      dollarBg.visible = false;
      DenominationImg.eventMode = "static";
      DenominationImg.cursor = "pointer";
      DenominationImg.addListener("click", () => {
        dollarBg.visible = true;
      });

      BetBg.eventMode = "static";
      BetBg.cursor = "pointer";
      BetBg.addEventListener("click", () => {
        winBlackBg.visible = false;
        console.log(betVal, "betValbetVal");
        if (betVal <= 50 || betVal < 150) {
          betVal += 50;
          BetValue.x = (app.screen.width - BetText.width) / 1.462;
          // MinorText.x = (app.screen.width - MinorText.width) / 6.2;
          BetSubValue.x = (app.screen.width - BetText.width) / 1.46;
          responsiveTextMiniMinor()
        } else if (betVal === 150 && betVal < 250) {
          betVal += 100;
          BetValue.x = (app.screen.width - BetText.width) / 1.46;
          // MinorText.x = (app.screen.width - MinorText.width) / 6.2;
          // BetSubValue.x = (app.screen.width - BetText.width) / 1.485;
          responsiveTextMiniMinor()
        } else if (betVal >= 250 && betVal < 500) {
          betVal += 250;
          BetValue.x = (app.screen.width - BetText.width) / 1.462;
          // MiniText.x = (app.screen.width - GrandTextImg.width) / 1.196;
          // MinorText.x = (app.screen.width - MinorText.width) / 6.2;
          BetSubValue.x = (app.screen.width - BetText.width) / 1.465;
          responsiveTextMiniMinor()
        } else {
          betVal = 50;
          if (betVal === 50) {
            BetValue.x = (app.screen.width - BetText.width) / 1.455;
            BetSubValue.x = (app.screen.width - BetText.width) / 1.47;
            // MiniText.x = (app.screen.width - GrandTextImg.width) / 1.185;
            // MinorText.x = (app.screen.width - MinorText.width) / 6.2;
            responsiveTextMiniMinor()
          }
        }
        MinorText.text = `$${(betVal * coinDenonimation).toFixed(2)}`;
        MiniText.text = `$${((betVal / 5) * coinDenonimation).toFixed(2)}`;

        BetValue.text = `${betVal}`;

        let input = parseFloat(betVal / 100);
        BetSuboutput =
          input % 1 === 0
            ? input.toFixed(2).replace(/\.0$/, "")
            : input.toFixed(2);
        BetSubValue.text = `$${(BetSuboutput * coinDenonimation).toFixed(2)}`;
        removingfunction()
        payLineTrueHandle()

        responsiveTextMiniMinor()
      });



      // // Payllines
      function indexVal(index) {
        if (index === 0) {
          return 2;
        }
        if (index === 1) {
          return 1;
        }
        if (index === 2) {
          return 0;
        }
      }
      let payLine;

      var intervalPayline;
      const createSinglePayline = (paylinesNum) => {
        clearInterval(intervalPayline);
        intervalPayline = setInterval(() => {
          console.log("paylinesNum in out", paylinesNum);
          if (paylinesNum?.length > 0) {
            console.log("paylinesNum inside", paylinesNum);
            payLine = new Sprite(
              Texture.from(
                `/assets/PayLines/PayLine_${paylinesNum[startCount]}.png`
              )
            );

            updatePopupUI(paylinesNum[startCount], winAmountShow[startCount]);
            console.log(winAmountShow, "pages", paylinesNum)
            payLineAnimateArr[startCount]?.splice(occurenceArr[startCount])
            payLineAnimateArr[startCount]?.forEach((el, i) => {

              let index = indexVal(el);

              if (
                reels[i]?.container?.children[index]?.texture?.name ===
                "WW"
              ) {
                reels[i].container.children[index].visible = false;
              }
              if (
                reels[i]?.container?.children[index]?.texture?.name ===
                winningSymbolArr[startCount]
              ) {
                let getname = getSpriteSheet(winningSymbolArr[startCount]);
                if (getname !== "PP") {
                  reels[i].container.children[index].visible = false;
                }
              }
            });

            console.log("paylinesNum inside-----", startCount);
            payLine.width = ((app.screen.width - GameFrame.width) * 3.48) / 2;
            payLine.height = app.screen.height / 1.62;
            payLine.x = (app.screen.width - payLine.width) / 2;
            payLine.y = (app.screen.height - payLine.height) / 2;
            app.stage.addChildAt(payLine, 35);
            removePy.push(payLine);
            setTimeout(() => {
              for (let i = 0; i < removePy.length; i++) {
                app.stage.removeChild(removePy[i]);
              }

              if (startCount === paylinesNum?.length - 1) {
                startCount = 0;
                console.log("paylinesNum inside-----0", startCount);
                comingImg();
                removeAllSprites();
              } else {
                startCount++;
                console.log("paylinesNum inside-----++", startCount);
                comingImg();
                removeAllSprites();
              }
            }, 2000);
          } else {
            clearInterval(intervalPayline);
          }
          comingSpriteAnimation()
        }, 2400);
        comingSpriteAnimation()
      };

      function comingImg() {
        for (let i = 0; i < 5; i++) {
          for (let j = 0; j < 3; j++) {
            if (
              reels[i]?.container?.children[j]?.visible === false &&
              reels[i]?.container?.children[j]?.texture?.name !== "TT"
            ) {
              reels[i].container.children[j].visible = true;
            }
          }
        }
      }

      function comingSpriteAnimation() {
        if (
          reels[0].container.children[0].visible === false &&
          reels[0].container.children[0].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[0].container.children[0].texture.name, 4, 3.5);
        }
        if (
          reels[0].container.children[1].visible === false &&
          reels[0].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[0].container.children[1].texture.name, 4, 2.05);
        }
        if (
          reels[0].container.children[2].visible === false &&
          reels[0].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[0].container.children[2].texture.name, 4, 1.435);
        }
        if (
          reels[1].container.children[0].visible === false &&
          reels[1].container.children[0].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[1].container.children[0].texture.name, 2.67, 3.5);
        }
        if (
          reels[1].container.children[1].visible === false &&
          reels[1].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[1].container.children[1].texture.name, 2.67, 2.05);
        }
        if (
          reels[1].container.children[2].visible === false &&
          reels[1].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[1].container.children[2].texture.name, 2.67, 1.435);
        }
        // y -- 3.5 , 2.05 , 1.435
        //x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

        if (
          reels[2].container.children[0].visible === false &&
          reels[2].container.children[0].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[2].container.children[0].texture.name, 2, 3.5);
        }
        if (
          reels[2].container.children[1].visible === false &&
          reels[2].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[2].container.children[1].texture.name, 2, 2.05);
        }
        if (
          reels[2].container.children[2].visible === false &&
          reels[2].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[2].container.children[2].texture.name, 2, 1.435);
        }
        // y -- 3.5 , 2.05 , 1.435
        //x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

        if (
          reels[3].container.children[0].visible === false &&
          reels[3].container.children[0].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[3].container.children[0].texture.name, 1.6, 3.5);
        }
        if (
          reels[3].container.children[1].visible === false &&
          reels[3].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[3].container.children[1].texture.name, 1.6, 2.05);
        }
        if (
          reels[3].container.children[2].visible === false &&
          reels[3].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[3].container.children[2].texture.name, 1.6, 1.435);
        }
        // y -- 3.5 , 2.05 , 1.435
        //x --  4, 2.67, 2, 1.6, 1.335 animateSpinSprite("DD",4,3.5);

        if (
          reels[4].container.children[0].visible === false &&
          reels[4].container.children[0].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[4].container.children[0].texture.name, 1.335, 3.5);
        }
        if (
          reels[4].container.children[1].visible === false &&
          reels[4].container.children[1].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[4].container.children[1].texture.name, 1.335, 2.05);
        }
        if (
          reels[4].container.children[2].visible === false &&
          reels[4].container.children[2].texture.name !== "TT"
        ) {
          animateSpinSprite(reels[4].container.children[2].texture.name, 1.335, 1.435);
        }

      }




      var removePy = [];
      const createLinesBunch_No = (paylinesNum) => {
        setTimeout(() => {
          winBlackBg.visible = true;
          console.log(paylinesNum, "paylineItmsBunch");
          // removePy = [...removePy, ...item];
          console.log(paylinesNum, "paylinesNumsssssssssss");

          paylinesNum.map((item) => {
            payLine = new Sprite(
              Texture.from(`/assets/PayLines/PayLine_${item}.png`)
            );
            payLine.width = ((app.screen.width - GameFrame.width) * 3.48) / 2;
            payLine.height = app.screen.height / 1.62;
            payLine.x = (app.screen.width - payLine.width) / 2;
            payLine.y = (app.screen.height - payLine.height) / 2;

            app.stage.addChildAt(payLine, 30);
            removePy.push(payLine);
          });
          console.log(payLine, "payLinessssssss", app.stage);
        }, 2000);

        setTimeout(() => {
          for (let i = 0; i < removePy.length; i++) {
            // removePy[i].destroy();
            app.stage.removeChild(removePy[i]);
          }
        }, 2500);

        setTimeout(() => {
          createSinglePayline(paylinesNum);
        }, 500);
      };

      const removePayLines = () => {
        console.log(removePy, "removePy");
        removePy.visible = false;
        app.stage.removeChild(removePy)
      };

      // **************************** History code************************** //



      // Create the pop-up container
      const popupContainer = new PIXI.Container();
      // popupContainer.visible = false;
      app.stage.addChild(popupContainer);

      // Add the pop-up background
      const popupBackground = new PIXI.Graphics();
      popupBackground.x = window.innerWidth > 1440 ? (app.screen.width - popupBackground.width) / 7 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? app.screen.width / 35 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (app.screen.width - popupBackground.width) / 8 : app.screen.width / 50;
      // popupBackground.x = (app.screen.width - popupBackground.width) / 8;
      popupBackground.y = window.innerWidth > 1440 ? (app.screen.height - popupBackground.height) / 2.2 : (window.innerWidth > 1366 && window.innerWidth < 1440) ? (app.screen.height - popupBackground.height) / 2.2 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (app.screen.height - popupBackground.height) / 2.5 : app.screen.height / 2;
      // popupBackground.y = (app.screen.height -popupBackground.height)/2.5 ;
      popupBackground.beginFill('000', 0.4);
      popupBackground.drawRect(0, 0, 100, 100);
      popupBackground.endFill();
      popupContainer.addChild(popupBackground);

      const contentText = new PIXI.Text('Line: 0\nPay: 0', { fontSize: 18, fill: '#b38b00' });
      contentText.x = 15;
      contentText.y = 25;
      popupBackground.addChild(contentText);
      popupContainer.visible = false;
      function updatePopupUI(line, pay) {
        popupContainer.visible = true;
        contentText.text = `Line: ${line}\nPay:  ${pay}`;
      }



      // Add Button added here
      const historyImage = new Sprite(Texture.from('/assets/Backgrounds/history.png'))
      const historyBtn = new Sprite(Texture.from('/assets/Backgrounds/Hud_tutoriales.png'))
      historyBtn.height = app.screen.height / 7;
      historyBtn.width = app.screen.height / 7;
      historyBtn.y = (app.screen.height - historyBtn.height) / 1.36;
      historyBtn.x = (app.screen.width - historyBtn.width) / 10;
      historyImage.height = app.screen.height / 12;
      historyImage.width = app.screen.height / 12;
      historyImage.y = (app.screen.height - historyImage.height) / 1.39;
      historyImage.x = (app.screen.width - historyImage.width) / 8.9;
      // historyBtn.x = Math.round((bottom.width * 4 - historyBtn.width) / 4.9);
      app.stage.addChild(historyBtn)
      app.stage.addChild(historyImage)
      historyBtn.eventMode = "static";
      historyBtn.cursor = "pointer";
      historyBtn.on('click', () => {
        setHtmlVisible(!isHtmlVisible); // Toggle HTML visibility
      });

      const spinRemainText = new TextStyle({
        fontSize: window.innerWidth > 1600 ? app.screen.width / 70 : (window.innerWidth > 1366 && window.innerWidth < 1600) ? app.screen.width / 70 : (window.innerWidth > 800 && window.innerWidth < 1367) ? app.screen.width / 70 : app.screen.width / 50,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });


      const spinText = new TextStyle({
        fontSize: window.innerWidth > 1600 ? app.screen.width / 40 : (window.innerWidth > 1366 && window.innerWidth < 1599) ? app.screen.width / 30 : (window.innerWidth > 800 && window.innerWidth < 1367) ? app.screen.width / 30 : app.screen.width / 50,
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440
      });


      const freeSpinImg = new Sprite(
        Texture.from("/assets/Backgrounds/Hud_tutoriales.png")
      );

      var freeSpinRemainingText = new Text(`Remaining \n Free Spin`, spinRemainText);
      // freeSpinRemainingText.style.fontSize = 30
      freeSpinRemainingText.x = app.screen.width / 1.33;
      freeSpinRemainingText.y = app.screen.height / 1.115
      app.stage.addChild(freeSpinRemainingText);



      freeSpinImg.scale.set(0.6)
      freeSpinImg.x = window.innerWidth > 1600 ? (app.screen.width - freeSpinImg.width) / 1.12 : (window.innerWidth > 1366 && window.innerWidth < 1599) ? (app.screen.width - freeSpinImg.width) / 1.1 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (app.screen.width - freeSpinImg.width) / 1.07 : app.screen.width / 50;
      // freeSpinImg.x = (app.screen.width - freeSpinImg.width) / 1.12;
      freeSpinImg.y = (app.screen.height - freeSpinImg.height) / 1.02;
      app.stage.addChild(freeSpinImg);
      // const freeSpinText = new Text(`${freeSpin || 0}`, spinText);

      const freeSpinText = new Text(`${freeSpin || 0}`, spinText);
      console.log(freeSpinText.text.length)

      if (freeSpinText.text.length == 2) {
        freeSpinText.x = window.innerWidth > 1600 ? X1percent * 3.5 : (window.innerWidth > 1366 && window.innerWidth < 1599) ? X1percent * 4.3 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (freeSpinImg.width - freeSpinText.width) / 0.8 : app.screen.width / 50;
      } else {
        freeSpinText.x = window.innerWidth > 1600 ? X1percent * 4 : (window.innerWidth > 1366 && window.innerWidth < 1599) ? X1percent * 5 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (freeSpinImg.width - freeSpinText.width) / 1 : app.screen.width / 50;
      }
      // freeSpinText.x = (freeSpinImg.width - freeSpinText.width)/1.1;
      freeSpinText.y = window.innerWidth > 1600 ? (freeSpinImg.height - freeSpinText.height) / 0.85 : (window.innerWidth > 1366 && window.innerWidth < 1599) ? (freeSpinImg.height - freeSpinText.height) / 0.85 : (window.innerWidth > 800 && window.innerWidth < 1367) ? (freeSpinImg.height - freeSpinText.height) / 0.9 : app.screen.width / 50;
      freeSpinImg.addChild(freeSpinText);
      freeSpinImg.visible = false;
      freeSpinRemainingText.visible = false;


      const startFreeSpin = new Sprite(
        Texture.from("/assets/FreeGames/DoradoPressPlay.png")
      );
      startFreeSpin.scale.set(0.12)
      startFreeSpin.x = (app.screen.width - startFreeSpin.width) / 2;
      startFreeSpin.y = (app.screen.height - startFreeSpin.height) / 2;
      app.stage.addChild(startFreeSpin);
      startFreeSpin.visible = false;





      let isRunning = false; // Flag to control the execution of the function
      let freeSpinInterval = null;

      function start() {

        startFreeSpin.visible = false;
        freeSpinModalContainer.visible = false;
        freeSpinInterval = setInterval(freeSpinHandle, 500);
      }




      // freeSpin = 5;
      async function freeSpinHandle() {
        clearInterval(freeSpinInterval);
        if (isRunning) {
          console.log("freeSpinHandle is stopped.");
          return; // Exit the function if isRunning is true
        }
        freeSpin--;
        // freeSpinText.text=freeSpin;
        console.log("Counter:", freeSpin, "winValue", winValue);
        removingfunction()
        payLineTrueHandle()
        startPlay()
        await gameSpinApi(true)

        freeSpinText.text = `${freeSpin}`;
        // Check if freeSpin has reached 10

        if (freeSpin < 1) {
          console.log("freeSpin has reached 10. Stopping freeSpinHandle.");
          clearInterval(freeSpinInterval); // Stop the interval
          freeSpinImg.visible = false;
          FoFImg.visible = true;
          freeSpinRemainingText.visible = false;
          bottom.eventMode = "static";
          bottom.cursor = "pointer";
          SpinBtn.alpha = 1;
          return; // Exit the function
        }
        freeSpinInterval = setInterval(freeSpinHandle, 5000);
        console.log('freeSpin444444----', freeSpin, "winCount", winCount)
      }



      startFreeSpin.eventMode = "static";
      startFreeSpin.cursor = "pointer";
      startFreeSpin.addListener("click", () => {
        start();
      });

      setTimeout(() => {
        if (currentfreeSpinGameLoad > 0) {
          noOfFreeSpinWonHandleGameLoad()
          freeSpinText.text = `${currentfreeSpinGameLoad}`;
        } else {
          setTimeout(() => {
            if (running === false) {
              bottom.eventMode = "static";
              bottom.cursor = "pointer";
            }
          }, 2500);
        }
      }, 500)

      const freeSpinStyle = new TextStyle({
        fontSize: app.screen.width / 40,
        // fontStyle: 'italic',
        fontFamily: "Arial , roboto, sans-serif",
        fontWeight: "bolder",
        fill: "#fff",
        align: 'center',
        // stroke: { color: 0x4a1850, width: 5 },
        dropShadow: {
          color: 0x000000,
          angle: Math.PI / 6,
          blur: 4,
          distance: 6
        },
        wordWrap: true,
        wordWrapWidth: 440,
      });

      const freeSpinModalContainer = new Container();
      const freeSpinModalBackground = new Sprite(Texture.from('/assets/MainBG/modalBg.png'));
      freeSpinModalBackground.scale.set(0.6);
      freeSpinModalBackground.x = (app.screen.width - freeSpinModalBackground.width) / 2;
      freeSpinModalBackground.y = (app.screen.height - freeSpinModalBackground.height) / 2;
      freeSpinModalContainer.addChild(freeSpinModalBackground);
      app.stage.addChild(freeSpinModalContainer);


      const freeSpinMesg = new Text(`You have ${currentfreeSpinGameLoad} free Spins left. 
        Click on play to consume the remaining free spin.`, {
        font: freeSpinStyle.font,
        fontSize: freeSpinStyle.fontSize,
        fill: freeSpinStyle.fill,
        align: 'center',
        wordWrap: true, // Add this line
        wordWrapWidth: freeSpinModalBackground.width - 20 // Adjust the width to your liking
      });


      freeSpinMesg.y = (freeSpinModalBackground.height - freeSpinMesg.height) / 1.1;
      freeSpinMesg.x = window.innerWidth > 1600 ? (freeSpinModalBackground.width - freeSpinMesg.width) / 0.1 : (window.innerWidth > 1440 && window.innerWidth <= 1599) ? (freeSpinModalBackground.width - freeSpinMesg.width) / 0.2 : (window.innerWidth > 1024 && window.innerWidth <= 1366) ? (freeSpinModalBackground.width - freeSpinMesg.width) / 0.45 : X1percent * 24;
      // freeSpinMesg.x = window.innerWidth > 1440 ? (freeSpinModalBackground.width - freeSpinMesg.width) / 0.1 : (window.innerWidth > 1024 && window.innerWidth <= 1366) ? (freeSpinModalBackground.width - freeSpinMesg.width) / 0.45 : X1percent * 24;
      freeSpinModalBackground.addChild(freeSpinMesg);
      freeSpinModalContainer.visible = false;


      const playFreeSpin = new Text(`Play`, freeSpinStyle);
      playFreeSpin.y = freeSpinModalContainer.height / 0.8;
      //playFreeSpin.x = 
      playFreeSpin.x = window.innerWidth > 1600 ? X1percent * 24 : (window.innerWidth > 1440 && window.innerWidth <= 1599) ? X1percent * 30 : (window.innerWidth > 1024 && window.innerWidth <= 1366) ? X1percent * 35 : X1percent * 47;
      freeSpinModalBackground.addChild(playFreeSpin)
      playFreeSpin.eventMode = "static";
      playFreeSpin.cursor = "pointer";
      playFreeSpin.addListener("pointerdown", () => {
        start();
      });



      function noOfFreeSpinWonHandleGameLoad() {
        freeSpinText.text = `${currentfreeSpin}`;
        FoFImg.visible = false
        freeSpinImg.visible = true;
        // winBlackBg.visible = true;
        freeSpinModalContainer.visible = true;
        freeSpinRemainingText.visible = true;
        bottom.eventMode = "none";
        bottom.cursor = "none";
        SpinBtn.alpha = 0.5;
      }


      function noOfFreeSpinWonHandle() {
        freeSpinText.text = `${currentfreeSpin}`;
        FoFImg.visible = false
        freeSpinImg.visible = true;
        startFreeSpin.visible = true;
        winBlackBg.visible = true;
        freeSpinRemainingText.visible = true;
        bottom.eventMode = "none";
        bottom.cursor = "none";
        SpinBtn.alpha = 0.5;
      }
      // *************** free spin End Code ****************************//


    })();


  }, []);

  const [loder, setLoder] = useState(false);
  const [show, setShow] = useState(false);


  useEffect(() => {
    // Function to simulate progress increment
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress >= 99) {
          clearInterval(interval); // Stop at 99
          setShow(localStorage.getItem("showModal"));
          if (prevProgress === 100) {
            return 100;
          }

          return 99;
        }
        return prevProgress + 1; // Increase progress by 1
      });
    }, 100); // Increment every 100ms


    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <>

      <div>
        <Preloader bgcolor='#19991d' progress={progress} height='10' loader={loder} logo={Foff} ></Preloader>
      </div>


      <div style={{ position: 'relative' }}>
        <div ref={pixiContainer} />
        {isHtmlVisible && (
          <div
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              color: 'black',
              backgroundColor: '#27272733',
              padding: '10px',
              borderRadius: '5px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            }}
          >
            <GameHistoryTable onClick={() => setHtmlVisible(false)} />
          </div>
        )}
      </div>

      <div style={{ position: 'relative' }}>
        <div ref={pixiContainer} />
        {isPayoutTable && (
          <div className="gameInfoContainer"
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              color: 'black',
              backgroundColor: '#272727cc',
              borderRadius: '5px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
              height: '100vh',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden'
            }}
          >
            <SliderItems onClick={() => setPayoutTable(false)} />
          </div>
        )}
      </div>


      <div style={{ position: 'relative' }}>
        <div ref={pixiContainer} />
        {isSessionExpired && (
          <div className="sessionExpired"
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              color: 'black',
              backgroundColor: '#000',
              borderRadius: '5px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
              height: '100vh',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden'
            }}
          >

            <SessionExpired onClick={() => {
              setSessionExpired(false)
              localStorage.removeItem("token");
              localStorage.removeItem("playerId");
              navigate("/")
            }} />
          </div>
        )}
      </div>
    </>
  );
};

export default Slot;



